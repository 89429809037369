import {
	EXCHANGE_BRAND_ENTITY_TYPES,
	EXCHANGE_BRAND_USER_TYPES,
	type ExchangeBrandEntityType,
	type ExchangeBrandUserType,
	type Permission,
	PERMISSIONS,
	type Role,
	ROLES,
	type ApiV3,
} from '@teamworksdev/influencer-core';

function isBrandUserProfile(profile: ApiV3.UserProfile | undefined): profile is ApiV3.BrandUserProfile {
	return !!profile && profile.role_id === ROLES.BRAND_USER;
}

function isTeamUserProfile(profile: ApiV3.UserProfile | undefined): profile is ApiV3.TeamUserProfile {
	return !!profile && profile.role_id === ROLES.TEAM_USER;
}

function isComplianceOfficerProfile(profile: ApiV3.UserProfile | undefined): profile is ApiV3.ComplianceOfficerProfile {
	return !!profile && profile.role_id === ROLES.COMPLIANCE_OFFICER;
}
class ProfileAccessService {
	private userProfile: ApiV3.UserProfile | undefined;
	private userRoles: Role[] = [];
	private userProfilePermissions: Permission[] = [];
	private userProfileExchangeEntityType?: ExchangeBrandEntityType | null = null;
	private userProfileExchangeUserType?: ExchangeBrandUserType | null = null;
	hasContentHubTaggedOnlyRead = false;
	hasContentHubRead = false;
	hasContentHubWrite = false;
	hasCollaborationHubRead = false;
	hasTeamManagementRead = false;
	hasLeaderboardRead = false;
	hasLeaderboardWrite = false;
	hasExchangeWrite = false;
	isAgent = false;
	isComplianceOfficer = false;
	canImpersonate = false;
	canAccessBulletins = false;
	canAccessTransactions = false;
	canCreateConversations = false;
	canViewAthleteContactInfo = false;
	hasAppSwitcher = false;
	isExchangeBrandAdmin = false;
	isServiceProvider = false;
	hasServiceProviderRead = false;
	hasTransactionsImportWrite = false;
	hasMyProfile = false;

	constructor(profile?: ApiV3.UserProfile) {
		this.userProfile = profile;
		this.userRoles = profile ? [profile.role_id] : [];

		if (profile?.role_id === ROLES.TEAM_USER) {
			this.userProfilePermissions = profile.academic_profile.group.permissions.map((p) => p.id);
		}

		if (profile?.role_id === ROLES.AGENT) {
			this.userRoles.push(ROLES.TEAM_USER);
			const agentDisallowedPermissions: Permission[] = [
				PERMISSIONS.TEAM_CONTENT_HUB_WRITE,
				PERMISSIONS.TEAM_ADMIN,
				PERMISSIONS.TEAM_CAMPAIGN_READ,
				PERMISSIONS.TEAM_CAMPAIGN_WRITE,
				PERMISSIONS.MEDIA_REQUEST_READ,
				PERMISSIONS.MEDIA_REQUEST_WRITE,
				PERMISSIONS.OPPORTUNITIES_WRITE,
				PERMISSIONS.LEADERBOARD_WRITE,
				PERMISSIONS.TRANSACTIONS_IMPORT_WRITE,
			];
			this.userProfilePermissions = profile.athlete.academic_profile.group.permissions
				.map((p) => p.id)
				.filter((p) => !agentDisallowedPermissions.includes(p));
		}

		if (profile?.role_id === ROLES.BRAND_USER) {
			this.userProfileExchangeEntityType = profile.exchange_brand.entity_type;
			this.userProfileExchangeUserType = profile.exchange_brand_user_type;
		}

		this.hasContentHubTaggedOnlyRead = this.isPermitted({
			roles: [ROLES.TEAM_USER],
			permissions: [
				PERMISSIONS.TEAM_ADMIN,
				PERMISSIONS.TEAM_CONTENT_HUB_WRITE,
				PERMISSIONS.TEAM_CONTENT_HUB_READ,
				PERMISSIONS.TEAM_CONTENT_HUB_MEDIA_TAGGED_ONLY_READ,
			],
		});

		this.hasContentHubRead = this.isPermitted({
			roles: [ROLES.TEAM_USER],
			permissions: [
				PERMISSIONS.TEAM_ADMIN,
				PERMISSIONS.TEAM_CONTENT_HUB_WRITE,
				PERMISSIONS.TEAM_CONTENT_HUB_READ,
			],
		});

		this.hasContentHubWrite = this.isPermitted({
			roles: [ROLES.TEAM_USER],
			permissions: [
				PERMISSIONS.TEAM_ADMIN,
				PERMISSIONS.TEAM_CONTENT_HUB_WRITE,
			],
		});

		this.hasCollaborationHubRead = this.isPermitted({
			roles: [ROLES.TEAM_USER],
			permissions: [
				PERMISSIONS.TEAM_ADMIN,
				PERMISSIONS.TEAM_CAMPAIGN_READ,
				PERMISSIONS.TEAM_CAMPAIGN_WRITE,
				PERMISSIONS.MEDIA_REQUEST_READ,
				PERMISSIONS.MEDIA_REQUEST_WRITE,
			],
		});

		this.hasTeamManagementRead = this.isPermitted({
			roles: [ROLES.TEAM_USER],
			permissions: [PERMISSIONS.TEAM_ADMIN],
		});

		this.hasLeaderboardRead = this.isPermitted({
			roles: [ROLES.TEAM_USER],
			permissions: [
				PERMISSIONS.LEADERBOARD_READ,
				PERMISSIONS.LEADERBOARD_WRITE,
			],
		});

		this.hasLeaderboardWrite = this.isPermitted({
			roles: [ROLES.TEAM_USER],
			permissions: [PERMISSIONS.LEADERBOARD_WRITE],
		});

		this.hasExchangeWrite = this.isPermitted({
			roles: [ROLES.BRAND_USER],
		});

		this.canAccessTransactions = this.isPermitted({
			roles: [
				ROLES.AGENT,
				ROLES.BRAND_USER,
				ROLES.COMPLIANCE_OFFICER,
			],
		});

		this.isAgent = this.isPermitted({
			roles: [ROLES.AGENT],
		});

		this.isComplianceOfficer = this.isPermitted({
			roles: [ROLES.COMPLIANCE_OFFICER],
		});

		this.canImpersonate = this.isPermitted({
			roles: [
				ROLES.SUPER_ADMINISTRATOR,
				ROLES.ADMINISTRATOR,
				ROLES.CLIENT_SUCCESS,
			],
		});

		this.canAccessBulletins = this.isPermitted({
			roles: [ROLES.BRAND_USER, ROLES.AGENT],
			exchangeEntityType: EXCHANGE_BRAND_ENTITY_TYPES.BUSINESS,
		});

		this.canCreateConversations = this.isPermitted({
			roles: [ROLES.BRAND_USER],
			exchangeEntityType: EXCHANGE_BRAND_ENTITY_TYPES.BUSINESS,
		});

		this.canViewAthleteContactInfo = this.isPermitted({
			roles: [ROLES.BRAND_USER],
			exchangeEntityType: EXCHANGE_BRAND_ENTITY_TYPES.BUSINESS,
		});

		this.isExchangeBrandAdmin = this.isPermitted({
			roles: [ROLES.BRAND_USER],
			exchangeUserType: EXCHANGE_BRAND_USER_TYPES.EXCHANGE_ADMIN,
		});

		this.isServiceProvider = this.isPermitted({
			roles: [ROLES.BRAND_USER, ROLES.AGENT],
		});

		this.hasServiceProviderRead = this.isPermitted({
			roles: [ROLES.TEAM_USER],
			permissions: [PERMISSIONS.SERVICE_PROVIDER_READ],
		});

		this.hasTransactionsImportWrite = this.isPermitted({
			roles: [ROLES.COMPLIANCE_OFFICER],
		}) || this.isPermitted({
			roles: [ROLES.TEAM_USER],
			permissions: [PERMISSIONS.TRANSACTIONS_IMPORT_WRITE],
		});

		this.hasMyProfile = this.isPermitted({
			roles: [
				ROLES.AGENT,
				ROLES.BRAND_USER,
				ROLES.TEAM_USER,
			],
		});
	}

	private hasRole(...roles: Role[]): boolean {
		if (!this.userProfile) return false;
		return roles.some((r) => this.userRoles.includes(r));
	}

	private hasPermission(...permissions: Permission[]): boolean {
		if (!this.userProfile) return false;
		if (!this.hasRole(ROLES.TEAM_USER)) return true;
		return permissions.some((p) => this.userProfilePermissions.includes(p));
	}

	private hasExchangeEntityType(exchangeEntityType: ExchangeBrandEntityType): boolean {
		if (!this.userProfile) return false;
		if (!this.hasRole(ROLES.BRAND_USER)) return true;
		return this.userProfileExchangeEntityType === exchangeEntityType;
	}

	private hasExchangeUserType(exchangeUserType: ExchangeBrandUserType): boolean {
		if (!this.userProfile) return false;
		if (!this.hasRole(ROLES.BRAND_USER)) return false;
		return this.userProfileExchangeUserType === exchangeUserType;
	}

	isPermitted(requiredAccess: { roles?: Role[]; permissions?: Permission[]; exchangeEntityType?: ExchangeBrandEntityType; exchangeUserType?: ExchangeBrandUserType }) {
		const { roles = [], permissions = [], exchangeEntityType, exchangeUserType } = requiredAccess;
		if (roles.length > 0 && !this.hasRole(...roles)) return false;
		if (permissions.length && !this.hasPermission(...permissions)) return false;
		if (exchangeEntityType !== undefined && !this.hasExchangeEntityType(exchangeEntityType)) return false;
		if (exchangeUserType !== undefined && !this.hasExchangeUserType(exchangeUserType)) return false;
		return true;
	}
}

export {
	isBrandUserProfile,
	isComplianceOfficerProfile,
	isTeamUserProfile,
	ProfileAccessService,
};
