import { i18n } from '@/plugins/i18n';
import { camelCase, sortBy } from 'lodash-es';
import { AGREEMENT_STATUSES } from '@teamworksdev/influencer-core';
import { PAYMENT_FREQUENCIES } from '@teamworksdev/vuetify';

type PaymentFrequency = typeof PAYMENT_FREQUENCIES[keyof typeof PAYMENT_FREQUENCIES];

const agreementStatuses = Object.keys(AGREEMENT_STATUSES).map((key) => ({
	id: AGREEMENT_STATUSES[key as keyof typeof AGREEMENT_STATUSES],
	name: i18n.global.t(`agreementStatuses.${camelCase(key)}.title`),
	conversationText: i18n.global.t(`agreementStatuses.${camelCase(key)}.conversationText`),
	agentText: (agent: string, athlete: string) => {
		return i18n.global.t(`agreementStatuses.${camelCase(key)}.conversationText`, { agent, athlete });
	},
	tooltip: undefined,
}));

const paymentFrequencies = sortBy(Object.keys(PAYMENT_FREQUENCIES).map((key) => ({
	id: PAYMENT_FREQUENCIES[key as keyof typeof PAYMENT_FREQUENCIES],
	name: i18n.global.t(`paymentFrequencies.${camelCase(key)}`),
})), 'name');

const paymentFrequenciesObj = paymentFrequencies.reduce((obj, frequency) => {
	obj[frequency.id] = frequency.name;
	return obj;
}, {} as { [key in PaymentFrequency]: string });

export {
	agreementStatuses,
	paymentFrequencies,
	paymentFrequenciesObj,
};
