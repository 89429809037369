import type { ApiV3 } from '@teamworksdev/influencer-core';
import { getList, isApiError } from './utilities/provider';
import type { AxiosRequestConfig } from 'axios';

const RESOURCE = 'conference-types';

export function useConferencesService(config?: Omit<AxiosRequestConfig, 'key'>) {
	const getConferences = async () => {
		const response = await getList<ApiV3.GetConferencesResponse>(RESOURCE, {
			key: 'getConferences',
			...config,
		});
		if (isApiError(response)) throw response;
		return response.data.data;
	};

	return {
		getConferences,
	};
}
