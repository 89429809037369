import { onBeforeUnmount, reactive, unref, watch, isRef, type MaybeRef } from 'vue';
import { cloneDeep } from 'lodash-es';

export interface PageGroupTitleActionItem {
	label: string;
	action?: () => void;
	icon: string;
	iconColor?: string;
	dataId: string;
}

interface UsePageGroupTitleState {
	title: string;
	items: PageGroupTitleActionItem[];
}

const DEFAULT_STATE: UsePageGroupTitleState = {
	title: '',
	items: [],
} as const;

const getDefaultState = () => (cloneDeep(DEFAULT_STATE));

const state = reactive<UsePageGroupTitleState>(getDefaultState());

function resetState() {
	Object.assign(state, getDefaultState());
}

export function usePageGroupTitle(title?: MaybeRef<string | undefined>, items?: MaybeRef<PageGroupTitleActionItem[] | undefined>) {
	const setTitle = (val: string) => state.title = val;

	if (isRef(title)) {
		watch(title, (val) => {
			const titleVal = unref(val);
			if (titleVal) setTitle(titleVal);
		}, { immediate: true });
	} else {
		if (title) setTitle(title);
	}

	const setItems = (val: PageGroupTitleActionItem[]) => state.items = val;

	if (isRef(items)) {
		watch(items, (val) => {
			const actionsVal = unref(val);
			if (actionsVal) setItems(actionsVal);
		}, { immediate: true });
	} else {
		if (items) setItems(items);
	}

	onBeforeUnmount(() => resetState());

	return {
		state,
		setTitle,
		setItems,
	};
}
