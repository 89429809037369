import { getVuetify } from '@teamworksdev/vuetify';
import 'vuetify/styles';
import '@material-design-icons/font/round.css';

import { VBtn } from 'vuetify/components/VBtn';



export const twDefaults = {
	VChipMini: {
		border: true,
		class: ['v-chip-mini', 'text-caption', 'font-weight-medium', 'px-1'],
		closeIcon: 'close',
		size: 'x-small',
		label: true,
		style: ['--v-activated-opacity: 0.03'],
	},
	VBtnPrimary: {
		color: 'primary-button',
		elevation: 1,
		variant: 'flat',
	} as VBtn['$props'],
	VBtnSecondary: {
		border: 'sm solid border-light opacity-100',
		class: 'text-decoration-none bg-background',
		elevation: 1,
		variant: 'outlined',
	} as VBtn['$props'],
	VBtnDanger: {
		color: 'error',
		elevation: 1,
		variant: 'outlined',
	} as VBtn['$props'],
	VBtnText: {
		size: 'small',
		variant: 'text',
	} as VBtn['$props'],
};

const vuetify = getVuetify({
	theme: {
		themes: {
			TwLight: {
				colors: {
					background: '#ffffff',
					surface: '#f4f5f6',
					primary: '#2cc3f2',
					'border-light': '#d0d5dd',
					'warning-25': '#fef4e6',
					'axle-navbar-bottom': '#2CC3F2',
				},
			},
			TwDark: {
				colors: {
					primary: '#2cc3f2',
					'primary-button': '#ffffff',
					'warning-25': '#fef4e6',
				},
			},
		},
	},
	defaults: {
		VSheet: {
			color: 'background',
		},
	},
});

export { vuetify };
