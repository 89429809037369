import { ref, reactive, watch } from 'vue';
import type { AgreementStatus, ApiV3 } from '@teamworksdev/influencer-core';

interface UseAgreementDrawerState {
	isOpen: boolean;
	activeAgreementUuid: string | undefined;
	onArchived: (() => void) | undefined;
	onPaymentSent: (() => void) | undefined;
	onPreviewMedia: ((payload: { index: number; media: ApiV3.Media[] }) => void) | undefined;
	onPreviewContract: ((payload: { url: string }) => void) | undefined;
	onTaskUpdated?: () => void;
	onTransactionStatusUpdated: (() => void) | undefined;
	onUpdated: (() => void) | undefined;
	onUpdateStatusSuccess: ((payload: AgreementStatus) => void) | undefined;
}

const DEFAULT_STATE: UseAgreementDrawerState = {
	isOpen: false,
	activeAgreementUuid: undefined,
	onArchived: undefined,
	onPaymentSent: undefined,
	onPreviewMedia: undefined,
	onPreviewContract: undefined,
	onTaskUpdated: undefined,
	onTransactionStatusUpdated: undefined,
	onUpdated: undefined,
	onUpdateStatusSuccess: undefined,
} as const;

const getDefaultState = () => ({ ...DEFAULT_STATE });

const state = reactive<UseAgreementDrawerState>(getDefaultState());

function resetState() {
	Object.assign(state, getDefaultState());
}

const isRendered = ref<boolean>(false);

watch(() => state.isOpen, (isOpen) => {
	if (isOpen && !isRendered.value) isRendered.value = true;
	if (!isOpen) resetState();
});

interface UseAgreementDrawerOptions {
	onArchived?: () => void;
	onPaymentSent?: () => void;
	onPreviewMedia?: (payload: { index: number; media: ApiV3.Media[] }) => void;
	onTaskUpdated?: () => void;
	onTransactionStatusUpdated?: () => void;
	onUpdated?: () => void;
	onUpdateStatusSuccess?: (payload: AgreementStatus) => void;
}

export function useAgreementDrawer(options?: UseAgreementDrawerOptions) {
	async function open(uuid?: string) {
		state.isOpen = true;
		state.activeAgreementUuid = uuid;
		state.onArchived = () => closeWithCallback(options?.onArchived);
		state.onPaymentSent = options?.onPaymentSent;
		state.onPreviewMedia = options?.onPreviewMedia;
		state.onUpdateStatusSuccess = options?.onUpdateStatusSuccess;
		state.onTaskUpdated = options?.onTaskUpdated;
		state.onTransactionStatusUpdated = options?.onTransactionStatusUpdated;
		state.onUpdated = options?.onUpdated;
	}

	function close() {
		state.isOpen = false;
	}

	function closeWithCallback(fn: (() => void) | undefined) {
		close();
		fn?.();
	}

	return {
		state,
		open,
		isRendered,
	};
}
