import { init } from '@module-federation/enhanced/runtime';

type Remote = Parameters<typeof init>[0]['remotes'][0];

const contracts: Remote = {
	name: 'contracts_app',
	alias: 'contracts',
	entry: import.meta.env.VITE_MFE_CONTRACTS_ENTRY,
	type: 'module',
};

export const initRemotes = () => {
	init({
		name: 'influencer_app',
		remotes: [contracts],
	});
};
