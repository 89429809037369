import type { ApiV3 } from '@teamworksdev/influencer-core';

const isBulletinApplicantExpanded = (data: ApiV3.BulletinApplicant | ApiV3.BulletinApplicantExpanded): data is ApiV3.BulletinApplicantExpanded => {
	return 'question_answers' in data;
};

type SingleBulletinApplicant = {
	applicationUuid: string;
	fullName: string | null;
	profileImage: string | null;
	applicantUuid: string | null;
	teamName: string;
	isApprovalRequiredForPayment: boolean;
	createdAt: string;
	updatedAt: string;
	questionAnswers: ApiV3.BulletinApplicantQuestionAnswer[];
};
class SingleBulletinApplicantProfile implements SingleBulletinApplicant {
	applicationUuid;
	fullName;
	profileImage;
	applicantUuid;
	teamName;
	isApprovalRequiredForPayment;
	createdAt;
	updatedAt;
	questionAnswers;

	constructor(data: ApiV3.BulletinApplicant | ApiV3.BulletinApplicantExpanded) {
		this.applicationUuid = data.uuid;
		this.fullName = data.user ? `${data.user.first_name} ${data.user.last_name}` : null;
		this.profileImage = data.user?.profile_image;
		this.applicantUuid = data.user?.uuid;
		this.teamName = data.team ? data.team.name : '';
		this.isApprovalRequiredForPayment = data.is_approval_required_for_payment;
		this.createdAt = data.created_at;
		this.updatedAt = data.updated_at;
		this.questionAnswers = [] as ApiV3.BulletinApplicantQuestionAnswer[];
		if (isBulletinApplicantExpanded(data)) {
			this.questionAnswers = data.question_answers;
		}
	}
}

export {
	type SingleBulletinApplicant,
	SingleBulletinApplicantProfile,
};
