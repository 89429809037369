import { onBeforeUnmount, reactive, ref } from 'vue';
import { useAuthStore } from '@/stores/authStore';
import { useUserStore } from '@/stores/userStore';
import type { ApiV3 } from '@teamworksdev/influencer-core';
import { useRouter } from 'vue-router';
import { resetGainsight } from '@/plugins/gainsight';
import { usePusher } from '@/plugins/pusher';
import { $ResetPinia } from '@/plugins/pinia';

interface UseBasicAuthOptions {
	onLogInSuccess?: () => void;
	onLogInError?: () => void;
}

export function useBasicAuth(options?: UseBasicAuthOptions) {
	const authStore = useAuthStore();
	const { unsetUser } = useUserStore();
	const router = useRouter();
	const pusher = usePusher();

	const isInitLoginBasic = ref(false);

	const defaultAuthTokenRequest: ApiV3.BasicAuthRequest = {
		email: '',
		password: '',
		remember_me: false,
	};
	const authTokenRequest: ApiV3.BasicAuthRequest = reactive({ ...defaultAuthTokenRequest });

	function resetLogInBasic() {
		Object.assign(authTokenRequest, { ...defaultAuthTokenRequest });
		isInitLoginBasic.value = false;
	}

	function handleSuccess() {
		if (options?.onLogInSuccess !== undefined) {
			options.onLogInSuccess();
		}
	}

	function handleError() {
		resetLogInBasic();
		if (options?.onLogInError !== undefined) {
			options.onLogInError();
		}
	}

	async function logInBasic() {
		try {
			await authStore.getTokenBasic(authTokenRequest);
			handleSuccess();
		} catch {
			handleError();
		}
	}

	function initLogInBasic(loginDetails: Omit<ApiV3.BasicAuthRequest, 'remember_me'>) {
		Object.assign(authTokenRequest, { ...loginDetails });
		isInitLoginBasic.value = true;
	}

	async function logOut(options?: {
		redirect?: boolean;
		deleteServerToken?: boolean;
	}) {
		const { redirect, deleteServerToken } = {
			redirect: true,
			deleteServerToken: true,
			...options,
		};

		const impersonationToken = authStore.impersonationToken;
		if (impersonationToken) await authStore.removeImpersonationToken(impersonationToken.uuid);

		if (deleteServerToken) {
			const token = authStore.token;
			if (token) await authStore.removeToken(token.uuid);
		} else {
			authStore.clearClientToken();
		}

		if (redirect) await router.push({ name: 'LoginIndex' });

		pusher.disconnect();
		unsetUser();
		resetGainsight();
		$ResetPinia().all();
	}

	onBeforeUnmount(() => resetLogInBasic());

	return {
		authTokenRequest,
		initLogInBasic,
		isInitLoginBasic,
		logInBasic,
		logOut,
		resetLogInBasic,
	};
}
