<template>
	<BaseDialog
		ref="confirmationDialog"
		v-model="state.isOpen"
		content-class="danger-confirmation-modal"
		persistent
		size="xsmall"
		:title="state.options?.title ?? ''"
		:close-button-visible="false"
		data-testid="confirmationDialog"
	>
		<template #default>
			<div
				v-if="state.options?.description"
				class="text-body-2 text-medium-emphasis"
			>
				{{ state.options?.description }}
			</div>
			<div
				v-if="state.options?.descriptionHtml"
				class="text-body-2 text-medium-emphasis"
				v-html="state.options?.descriptionHtml"
			/>
		</template>
		<template #actions>
			<VBtnSecondary
				ref="buttonCancel"
				data-testid="buttonCancel"
				:disabled="state.buttonsDisabled"
				@click="respond(false)"
			>
				{{ state.options?.cancelText }}
			</VBtnSecondary>
			<VBtnPrimary
				ref="buttonConfirm"
				:color="state.options?.confirmColor"
				:loading="state.isLoading"
				:disabled="state.buttonsDisabled"
				data-testid="buttonConfirm"
				@click="respond(true)"
			>
				{{ state.options?.confirmText }}
			</VBtnPrimary>
		</template>
	</BaseDialog>
</template>

<script lang="ts" setup>
import { useConfirmationDialog } from '@/composables/useConfirmationDialog';
import { BaseDialog } from '@teamworksdev/vuetify';

const {
	state,
	respond,
} = useConfirmationDialog();
</script>
