import { i18n } from '@/plugins/i18n';
import { sortBy, camelCase } from 'lodash-es';
import {
	BaseTransaction,
	Transaction,
	TransactionListTransaction,
} from '@/types/transactions';
import {
	EXCHANGE_STATUSES,
	type ExchangeStatus,
	LEADERBOARD_STATUSES,
	TRANSACTION_TYPES,
	type TransactionType,
	LEADERBOARD_ONLY_TRANSACTION_TYPE_IDS,
	NON_LEADERBOARD_TRANSACTION_TYPE_IDS,
	PAYABLE_STATUSES,
	type PayableStatus,
	type TransactionStatus,
	TRANSACTION_STATUSES,
	TRANSACTION_PAYMENT_TYPES,
} from '@teamworksdev/influencer-core';

const transactionTypes = sortBy(Object.keys(TRANSACTION_TYPES).map((key) => ({
	id: TRANSACTION_TYPES[key as keyof typeof TRANSACTION_TYPES],
	name: i18n.global.t(`transactionTypes.${camelCase(key)}`),
})), 'name');

const nonLeaderboardTransactionTypes = transactionTypes.filter((transactionType) => NON_LEADERBOARD_TRANSACTION_TYPE_IDS.includes(transactionType.id));
const leaderboardTransactionTypes = transactionTypes.filter((transactionType) => LEADERBOARD_ONLY_TRANSACTION_TYPE_IDS.includes(transactionType.id));

const transactionTypesObj = transactionTypes.reduce((obj, transactionType) => {
	obj[transactionType.id] = transactionType.name;
	return obj;
}, {} as { [key in TransactionType]: string });

const exchangeStatuses = Object.keys(EXCHANGE_STATUSES).map((key) => ({
	id: EXCHANGE_STATUSES[key as keyof typeof EXCHANGE_STATUSES],
	name: i18n.global.t(`exchangeStatuses.${camelCase(key)}.title`),
	tooltip: i18n.global.t(`exchangeStatuses.${camelCase(key)}.tooltip`),
	conversationText: i18n.global.t(`exchangeStatuses.${camelCase(key)}.conversationText`),
	agentText: (agent: string, athlete: string) => {
		return i18n.global.t(`exchangeStatuses.${camelCase(key)}.agentText`, { agent, athlete });
	},
}));

const leaderboardStatuses = Object.keys(LEADERBOARD_STATUSES).map((key) => ({
	id: LEADERBOARD_STATUSES[key as keyof typeof LEADERBOARD_STATUSES],
	name: i18n.global.t(`leaderboardStatuses.${camelCase(key)}`),
	tooltip: undefined,
}));

const payableStatuses = Object.keys(PAYABLE_STATUSES).map((key) => ({
	id: PAYABLE_STATUSES[key as keyof typeof PAYABLE_STATUSES],
	name: i18n.global.t(`payableStatuses.${camelCase(key)}`),
	tooltip: undefined,
}));

const payableStatusByExchangeStatus: Record<ExchangeStatus, PayableStatus> = {
	0: PAYABLE_STATUSES.AWAITING_ATHLETE,
	1: PAYABLE_STATUSES.ATHLETE_DECLINED,
	2: PAYABLE_STATUSES.CAN_PAY,
	3: PAYABLE_STATUSES.EXPIRED,
	4: PAYABLE_STATUSES.ALREADY_PAID,
	5: PAYABLE_STATUSES.CAN_PAY,
	30: PAYABLE_STATUSES.ALREADY_PAID,
	40: PAYABLE_STATUSES.ALREADY_PAID,
	50: PAYABLE_STATUSES.ALREADY_PAID,
	60: PAYABLE_STATUSES.ALREADY_PAID,
	70: PAYABLE_STATUSES.ALREADY_PAID,
	80: PAYABLE_STATUSES.ALREADY_PAID,
	90: PAYABLE_STATUSES.ALREADY_PAID,
	100: PAYABLE_STATUSES.ALREADY_PAID,
	110: PAYABLE_STATUSES.ALREADY_PAID,
	120: PAYABLE_STATUSES.ALREADY_PAID,
	130: PAYABLE_STATUSES.ALREADY_PAID,
	140: PAYABLE_STATUSES.PAID_OUTSIDE_INFLCR,
};

const transactionStatuses = Object.keys(TRANSACTION_STATUSES).map((key) => ({
	id: TRANSACTION_STATUSES[key as keyof typeof TRANSACTION_STATUSES],
	name: i18n.global.t(`transactionStatuses.${camelCase(key)}.title`),
	tooltip: i18n.global.t(`transactionStatuses.${camelCase(key)}.tooltip`),
}));

const transactionPaymentTypes = Object.keys(TRANSACTION_PAYMENT_TYPES).map((key) => ({
	id: TRANSACTION_PAYMENT_TYPES[key as keyof typeof TRANSACTION_PAYMENT_TYPES],
	name: i18n.global.t(`transactionPaymentTypes.${camelCase(key)}`),
}));

const approvedExchangeStatuses: ExchangeStatus[] = [
	EXCHANGE_STATUSES.APPROVED,
	EXCHANGE_STATUSES.PAYMENT_FAILED,
	EXCHANGE_STATUSES.PAYMENT_QUEUE_FAILED,
];

const approvedTransactionStatuses: TransactionStatus[] = [TRANSACTION_STATUSES.APPROVED, TRANSACTION_STATUSES.REVIEWED];

function isTransactionListTransaction(transaction: BaseTransaction | Transaction | TransactionListTransaction): transaction is TransactionListTransaction {
	return (transaction as TransactionListTransaction).tasksOverview !== undefined;
}
function isTransaction(transaction: BaseTransaction | Transaction | TransactionListTransaction): transaction is Transaction {
	return (transaction as Transaction).tasks !== undefined;
}

function transactionPayableConditions(transaction: BaseTransaction): { isExchangeStatusApproved: boolean; isTransactionStatusApproved: boolean } {
	const isExchangeStatusApproved = transaction.exchangeStatus !== null && approvedExchangeStatuses.includes(transaction.exchangeStatus);
	const isTransactionStatusApproved = transaction.status !== null && transaction.isApprovalRequiredForPayment
		? approvedTransactionStatuses.includes(transaction.status)
		: true;
	return { isExchangeStatusApproved, isTransactionStatusApproved };
}

function transactionIsPayable(transaction: BaseTransaction): boolean {
	const { isExchangeStatusApproved, isTransactionStatusApproved } = transactionPayableConditions(transaction);

	return isExchangeStatusApproved && isTransactionStatusApproved;
}

function transactionPayableStatus(transaction: BaseTransaction): PayableStatus {
	const { isExchangeStatusApproved, isTransactionStatusApproved } = transactionPayableConditions(transaction);

	const conditionToPayableStatusMap = [
		{ condition: () => !isExchangeStatusApproved, status: transaction.exchangeStatus !== null ? payableStatusByExchangeStatus[transaction.exchangeStatus] : PAYABLE_STATUSES.NOT_PAYABLE },
		{ condition: () => !isTransactionStatusApproved, status: PAYABLE_STATUSES.AWAITING_SCHOOL },
	];
	for (const { condition, status } of conditionToPayableStatusMap) {
		if (condition()) {
			return status;
		}
	}
	return PAYABLE_STATUSES.CAN_PAY;
}

export {
	transactionTypes,
	nonLeaderboardTransactionTypes,
	leaderboardTransactionTypes,
	transactionTypesObj,
	exchangeStatuses,
	approvedExchangeStatuses,
	approvedTransactionStatuses,
	leaderboardStatuses,
	payableStatuses,
	transactionStatuses,
	transactionPaymentTypes,
	isTransactionListTransaction,
	isTransaction,
	transactionIsPayable,
	transactionPayableStatus,
};
