import { useI18n } from 'vue-i18n';
import isEmail from 'validator/lib/isEmail';
import isURL from 'validator/lib/isURL';
import type { IsURLOptions } from 'validator/lib/isURL';
import { isValidPhoneNumber, type CountryCode } from 'libphonenumber-js';
import { isEmpty } from 'lodash-es';
import { isFuture } from 'date-fns';

export function useFormRules() {
	const { n, t } = useI18n();

	const emailValid = (v: string) => isEmail(v) || t('validations.emailValid');
	const urlValid = (v: string, options?: IsURLOptions) => isURL(v, options) || t('validations.urlValid');
	const urlValidOrEmpty = (v: string, options?: IsURLOptions) => !v || isURL(v, options) || t('validations.urlValid');
	const urlValidWithProtocol = (v: string, options?: IsURLOptions) => {
		return !v || isURL(v, { require_protocol: true, require_valid_protocol: true, ...options }) || t('validations.urlValidWithProtocol');
	};
	const fieldRequired = (v: any) => {
		if (['number', 'boolean'].includes(typeof v)) return true;
		return !isEmpty(typeof v === 'string' ? v.trim() : v) || t('validations.fieldRequired');
	};
	const passwordMin = (v: string) => v.length >= 8 || t('validations.passwordMin');
	const passwordUpper = (v: string) => /[A-Z]/.test(v) || t('validations.passwordUpper');
	const passwordLower = (v: string) => /[a-z]/.test(v) || t('validations.passwordLower');
	const passwordNumber = (v: string) => /[0-9]/.test(v) || t('validations.passwordNumber');
	const passwordSpecialCharacter = (v: string) => /[#?!@$%^&*-]/.test(v) || t('validations.passwordSpecialCharacter');
	const maxLength = (v: string, max: number) => v.length <= max || t('validations.maxLength', { max });
	const exactLength = (v: string, length: number) => v.length === length || t('validations.exactLength', { length });
	const matchRegex = (regex: RegExp, message: string) => (v: string) => regex.test(v) || message;
	const isUnique = (v: string, list: string[]) => !list.includes(v) || t('validations.isUnique');
	const costValid = (v: number) => {
		const costRegex = /^(?:\d{0,10})\.\d{2}$/;
		return costRegex.test(v.toString()) || t('validations.costValid');
	};
	const isInteger = (v: string) => Number.isSafeInteger(Number(v)) || t('validations.isInteger');
	const phoneValid = (v: string, countryCode: CountryCode = 'US') => isValidPhoneNumber(v, countryCode) || t('validations.phoneValid');
	const mustBeTrue = (v: boolean) => v || t('validations.fieldRequired');
	const passwordMatch = (v: string, match: string) => v === match || t('validations.exactMatch');
	const futureDate = (v: string) => !v || isFuture(v) || t('validations.futureDate');
	const min = (v: number, min: number) => v >= min || t('validations.min', { min: n(min) });
	const max = (v: number, max: number) => v <= max || t('validations.max', { max: n(max) });
	const isHexColor = (v: string) => !v || /^#([0-9A-F]{3}|[0-9A-F]{6})$/i.test(v) || t('validations.hexColor');

	return {
		emailValid,
		fieldRequired,
		isUnique,
		passwordMin,
		passwordMatch,
		maxLength,
		exactLength,
		urlValid,
		matchRegex,
		urlValidOrEmpty,
		urlValidWithProtocol,
		costValid,
		isInteger,
		phoneValid,
		mustBeTrue,
		passwordUpper,
		passwordLower,
		passwordNumber,
		passwordSpecialCharacter,
		futureDate,
		min,
		max,
		isHexColor,
	};
}
