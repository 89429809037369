import type {
	ApiV3,
} from '@teamworksdev/influencer-core';
import { post, remove, isApiError, getSingle, patchWithoutId } from './utilities/provider';
import type { AxiosRequestConfig } from 'axios';

const RESOURCE = 'auth';

export function useAuthService(config?: Omit<AxiosRequestConfig, 'key'>) {
	const getTokenBasic = async (model: ApiV3.BasicAuthRequest) => {
		const response = await post<ApiV3.AuthToken>(`${RESOURCE}/token`, model, <AxiosRequestConfig>{
			requiresAuth: false,
			requiresAcceptedTou: false,
			...config,
		});
		if (isApiError(response)) throw response;
		return response.data;
	};

	const getTokenTeamworks = async (model: ApiV3.TeamworksAuthRequest) => {
		const response = await post<ApiV3.AuthToken>(`${RESOURCE}/teamworks/token`, model,
		<AxiosRequestConfig>{
			requiresAuth: false,
			requiresAcceptedTou: false,
			...config,
		});
		if (isApiError(response)) throw response;
		return response.data;
	};

	const getTokenImpersonation = async (model: ApiV3.ImpersonationAuthRequest) => {
		const response = await post<ApiV3.AuthToken>(`${RESOURCE}/token/impersonate`, model, <AxiosRequestConfig>{
			requiresAuth: true,
			requiresAcceptedTou: false,
			key: 'impersonation',
			...config,
		});
		if (isApiError(response)) throw response;
		return response.data;
	};

	const getTokenAxle = async (model: ApiV3.AxleAuthRequest) => {
		const response = await post<ApiV3.AuthToken>(`${RESOURCE}/axle/token/from`, model, <AxiosRequestConfig>{
			requiresAuth: false,
			requiresAcceptedTou: false,
			key: 'getTokenAxle',
			...config,
		});
		if (isApiError(response)) throw response;
		return response.data;
	};

	const getOutgoingAxleToken = async () => {
		const response = await post<ApiV3.AxleToken>(`${RESOURCE}/axle/token/to`, {}, {
			key: 'getOutgoingAxleToken',
			...config,
		});
		if (isApiError(response)) throw response;
		return response.data;
	};

	const getUnityWebToken = async () => {
		const response = await post<ApiV3.AxleToken>(`${RESOURCE}/axle/token/unity-web`, {}, {
			key: 'getUnityWebToken',
			...config,
		});
		if (isApiError(response)) throw response;
		return response.data;
	};

	const deleteToken = async (tokenUuid: string) => {
		const response = await remove(`${RESOURCE}/token`, tokenUuid, <AxiosRequestConfig>{
			requiresInflcrId: false,
			requiresAcceptedTou: false,
			bypassImpersonation: true,
			...config,
		});
		if (isApiError(response)) throw response;
		return response.status;
	};

	const introspectToken = async () => {
		const response = await getSingle<ApiV3.AuthToken>(`${RESOURCE}/token/introspect`, '', <AxiosRequestConfig>{
			key: 'introspectToken',
			requiresAuth: true,
			requiresInflcrId: false,
			requiresAcceptedTou: false,
			...config,
		});
		if (isApiError(response)) throw response;
		return response.data;
	};

	const forgotPassword = async (model: ApiV3.ForgotPasswordRequest) => {
		const response = await post(`${RESOURCE}/reset-password`, model, {
			key: 'forgotPassword',
			requiresAuth: false,
			requiresAcceptedTou: false,
			requiresInflcrId: false,
			...config,
		});
		if (isApiError(response)) throw response;
		return response.status;
	};

	const resetPassword = async (model: ApiV3.ResetPasswordRequest) => {
		const response = await patchWithoutId(`${RESOURCE}/reset-password`, model, {
			key: 'resetPassword',
			requiresAuth: false,
			requiresAcceptedTou: false,
			requiresInflcrId: false,
			bypassErrorToast: true,
			...config,
		});
		if (isApiError(response)) throw response;
		return response.status;
	};

	return {
		getTokenBasic,
		getTokenTeamworks,
		getTokenImpersonation,
		getTokenAxle,
		getOutgoingAxleToken,
		getUnityWebToken,
		deleteToken,
		introspectToken,
		forgotPassword,
		resetPassword,
	};
}
