import { type AgreementPaymentTablePayee } from '@/components/exchange/ExchangeAgreementPaymentTable.vue';
import { reactive, ref, watch, type MaybeRef, unref } from 'vue';

interface UseExchangeAgreementPayeeDrawerState {
	isOpen: boolean;
	payee: AgreementPaymentTablePayee | undefined;
	hideActions?: boolean;
	onUpdated?: (payload: AgreementPaymentTablePayee) => void;
	onUpdateAll?: (payload: AgreementPaymentTablePayee['transactions']) => void;
}

const DEFAULT_STATE: UseExchangeAgreementPayeeDrawerState = {
	isOpen: false,
	payee: undefined,
	hideActions: false,
	onUpdated: undefined,
	onUpdateAll: undefined,
} as const;

const getDefaultState = () => ({ ...DEFAULT_STATE });

const state = reactive<UseExchangeAgreementPayeeDrawerState>(getDefaultState());

function resetState() {
	Object.assign(state, getDefaultState());
}

const isRendered = ref<boolean>(false);

watch(() => state.isOpen, (isOpen) => {
	if (isOpen && !isRendered.value) isRendered.value = true;
	if (!isOpen) resetState();
});

interface UseExchangeAgreementPayeeDrawerOptions {

	hideActions?: boolean;
	onUpdated?: (payload: AgreementPaymentTablePayee) => void;
	onUpdateAll?: (payload: AgreementPaymentTablePayee['transactions']) => void;
}

export function useExchangeAgreementPayeeDrawer(options?: UseExchangeAgreementPayeeDrawerOptions) {
	const open = (payee?: MaybeRef<AgreementPaymentTablePayee>) => {
		state.payee = unref(payee);
		state.hideActions = options?.hideActions;
		state.onUpdated = options?.onUpdated;
		state.onUpdateAll = options?.onUpdateAll;
		state.isOpen = true;
	};

	return {
		state,
		isRendered,
		resetState,
		open,
	};
}
