<template>
	<div class="login-content-default">
		<LoginPageHeader>
			<template #title>
				{{ t('pageLogin.title') }}
			</template>
			<template #subtitle>
				{{ isImpersonate ? t('pageLogin.impersonateSubtitle') : t('pageLogin.subtitle') }}
			</template>
		</LoginPageHeader>
		<VForm
			ref="formLogin"
			v-model="isFormValid"
			:disabled="isLoading"
			@submit.prevent="submit"
		>
			<BaseField
				v-slot="{id}"
				:label="t('emailAddress')"
			>
				<VTextField
					:id="id"
					ref="fieldEmailAddress"
					v-model="emailAddress"
					:rules="[fieldRequired, emailValid]"
					data-testid="fieldEmailAddress"
				/>
			</BaseField>
			<BaseField
				v-slot="{id}"
				:label="t('password')"
			>
				<VTextField
					:id="id"
					ref="fieldPassword"
					v-model="password"
					:type="isShowPassword ? 'text' : 'password'"
					:append-inner-icon="
						isShowPassword ? 'visibility' : 'visibility_off'
					"
					:rules="[fieldRequired]"
					data-testid="fieldPassword"
					@click:append-inner="isShowPassword = !isShowPassword"
				/>
			</BaseField>
			<div class="d-flex justify-end align-center">
				<RouterLink :to="{ name: 'LoginForgotPassword' }">
					<span class="font-weight-semibold">
						{{ t('pageLogin.forgotPassword') }}
					</span>
				</RouterLink>
			</div>
			<VBtnPrimary
				type="submit"
				class="mt-6"
				:disabled="!isFormValid"
				:loading="isLoading"
				size="large"
				block
				data-testid="buttonLogInBasic"
			>
				{{ t('logIn') }}
			</VBtnPrimary>
		</VForm>
		<div class="separator my-4">
			<span>{{ t('or') }}</span>
		</div>
		<VBtnSecondary
			ref="buttonLogInTeamworks"
			:disabled="isLoading"
			size="large"
			prepend-icon="$twi-teamworks"
			block
			data-testid="buttonLogInTeamworks"
			@click="logInTeamworks"
		>
			{{ t('pageLogin.signInWithTeamworks') }}
		</VBtnSecondary>
		<AppBadges />
	</div>
</template>

<script setup lang="ts">
import { computed, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { useFormRules } from '@/composables/useFormRules';
import AppBadges from '@/components/login/AppBadges.vue';
import { BaseField } from '@teamworksdev/vuetify';
import LoginPageHeader from '@/components/login/LoginPageHeader.vue';
import type { ApiV3 } from '@teamworksdev/influencer-core';
import { useRouter } from 'vue-router';

defineProps({
	isLoading: { type: Boolean, default: false },
});

const router = useRouter();
const { t } = useI18n();
const { fieldRequired, emailValid } = useFormRules();

const isFormValid = ref(false);
const isShowPassword = ref(false);
const emailAddress = ref('');
const password = ref('');

const emit = defineEmits<{
	(e: 'logInBasic', payload: Omit<ApiV3.BasicAuthRequest, 'remember_me'>): void;
	(e: 'logInTeamworks'): void;
}>();

const isImpersonate = computed<boolean>(() => {
	return router.currentRoute.value.name === 'LoginImpersonate';
});

function submit() {
	emit('logInBasic', {
		email: emailAddress.value,
		password: password.value,
	});
}

function logInTeamworks() {
	emit('logInTeamworks');
}
</script>

<style scoped lang="scss">
.separator {
	align-items: center;
	display: flex;
	height: calc(var(--spacer) * 6);
	justify-content: center;
	position: relative;

	& > span {
		background-color: rgb(var(--v-theme-background));
		padding: 0 calc(var(--spacer) * 2);
		position: relative;
		z-index: 1;
	}

	&::after {
		content: '';
		width: 100%;
		height: 1px;
		background-color: rgb(var(--v-theme-border-light));
		position: absolute;
		z-index: 0;
	}
}
</style>
