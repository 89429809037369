import { useConferencesService } from '@/api/conferencesService';
import type { ApiV3 } from '@teamworksdev/influencer-core';
import { sortBy } from 'lodash-es';
import { defineStore } from 'pinia';
import { computed, ref } from 'vue';

export const useConferencesStore = defineStore('conferences', () => {
	const conferencesRaw = ref<ApiV3.Conference[]>();

	const conferences = computed<ApiV3.Conference[]>(() => sortBy(conferencesRaw.value, 'name'));
	const isFetchingConferences = ref(false);

	const fetchConferences = async () => {
		if (conferencesRaw.value || isFetchingConferences.value) return;
		isFetchingConferences.value = true;
		try {
			conferencesRaw.value = await useConferencesService().getConferences();
		} catch (error) {
			console.error(error);
		} finally {
			isFetchingConferences.value = false;
		}
	};

	const $reset = () => {
		conferencesRaw.value = undefined;
	};

	return {
		conferences,
		isFetchingConferences,
		fetchConferences,
		$reset,
	};
});
