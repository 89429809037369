import { createI18n } from 'vue-i18n';
import messages from '@intlify/unplugin-vue-i18n/messages';
import type { DateTimeFormat, DateTimeFormats } from '@intlify/core-base';
import { mergeLibraryTranslations } from '@teamworksdev/vuetify';

const commonPercentFormats: Intl.NumberFormatOptions = {
	style: 'percent',
	maximumFractionDigits: 2,
};
const commonDecimalFormats: Intl.NumberFormatOptions = {
	style: 'decimal',
	minimumFractionDigits: 1,
	maximumFractionDigits: 2,
};

const commonCurrencyFormats: Intl.NumberFormatOptions = {
	style: 'currency',
	currency: 'USD',
	currencyDisplay: 'symbol',
};

const numberFormats = {
	'en-US': {
		currency: commonCurrencyFormats,
		percent: commonPercentFormats,
		decimal: commonDecimalFormats,
	},
	'pt-BR': {
		currency: commonCurrencyFormats,
		percent: commonPercentFormats,
		decimal: commonDecimalFormats,
	},
	'pt-PT': {
		currency: commonCurrencyFormats,
		percent: commonPercentFormats,
		decimal: commonDecimalFormats,
	},
	'it-IT': {
		currency: commonCurrencyFormats,
		percent: commonPercentFormats,
		decimal: commonDecimalFormats,
	},
	'fr-FR': {
		currency: commonCurrencyFormats,
		percent: commonPercentFormats,
		decimal: commonDecimalFormats,
	},
	'es-419': {
		currency: commonCurrencyFormats,
		percent: commonPercentFormats,
		decimal: commonDecimalFormats,
	},
	'de-DE': {
		currency: commonCurrencyFormats,
		percent: commonPercentFormats,
		decimal: commonDecimalFormats,
	},
};

const datetimeFormat: DateTimeFormat = {
	short: { year: 'numeric', month: 'short', day: 'numeric' },
	long: { year: 'numeric', month: 'long', day: 'numeric' },
};

const datetimeFormats: DateTimeFormats = {
	'en-US': datetimeFormat,
	'pt-BR': datetimeFormat,
	'pt-PT': datetimeFormat,
	'it-IT': datetimeFormat,
	'fr-FR': datetimeFormat,
	'es-419': datetimeFormat,
	'de-DE': datetimeFormat,
};

const i18n = createI18n({
	legacy: false,
	locale: 'en-US',
	fallbackLocale: 'en-US',
	messages,
	numberFormats,
	datetimeFormats,
});

mergeLibraryTranslations(i18n);

export { i18n };
