import { useUserStore } from '@/stores/userStore';
import { isComplianceOfficerProfile } from '@/types/users';
import { SCHOOL_FLAGS } from '@teamworksdev/influencer-core';
import type { RouteRecordRaw } from 'vue-router';

const routes: RouteRecordRaw[] = [
	{
		path: '/compliance',
		beforeEnter: [canAccessComplianceRoutes],
		children: [
			{
				path: 'transactions',
				name: 'ComplianceTransactionsParent',
				component: () => import('@/views/ExchangeTransactionsParent.vue'),
				beforeEnter: [canAccessTransactions],
				children: [
					{
						path: '',
						name: 'ComplianceTransactions',
						component: () => import('@/views/TransactionsIndex.vue'),
						meta: {
							requiresAuth: true,
							layout: 'LayoutAuthenticated',
							title: 'pageExchangeTransactions.metaTitle',
							requiredAccess: 'canAccessTransactions',
							profileSwitchRedirect: () => {
								const userStore = useUserStore();
								return userStore.profileAccess.isComplianceOfficer
									? 'ComplianceTransactions'
									: 'ExchangeTransactions';
							},
						},
					},
				],
			},
			{
				path: 'transactions/:transactionUuid',
				name: 'ComplianceTransaction',
				component: () => import('@/views/ExchangeTransaction.vue'),
				props: true,
				meta: {
					requiresAuth: true,
					layout: 'LayoutAuthenticated',
					title: 'pageTransaction.metaTitle',
					requiredAccess: 'canAccessTransactions',
					profileSwitchRedirect: 'ComplianceTransactions',
				},
				beforeEnter: [canAccessTransactions],
			},
			{
				path: 'agreements',
				name: 'ComplianceAgreements',
				redirect: { name: 'ComplianceTransactions' },
			},
			{
				path: 'agreements/:agreementUuid',
				name: 'ComplianceAgreement',
				component: () => import('@/views/ExchangeAgreement.vue'),
				props: true,
				meta: {
					requiresAuth: true,
					layout: 'LayoutAuthenticated',
					title: 'pageAgreement.metaTitle',
					requiredAccess: 'canAccessTransactions',
				},
				beforeEnter: [canAccessTransactions],
			},
			{
				path: 'settings',
				name: 'ComplianceSettingsParent',
				component: () => import('@/views/ComplianceSettingsParent.vue'),
				redirect: { name: 'ComplianceSettingsSchool' },
				children: [
					{
						path: 'school',
						name: 'ComplianceSettingsSchool',
						component: () => import('@/views/ComplianceSettingsSchool.vue'),
						meta: {
							layout: 'LayoutAuthenticated',
							requiresAuth: true,
							title: 'pageComplianceSettingsSchool.metaTitle',
							requiredAccess: 'isComplianceOfficer',
							requiredFeatureFlags: { isComplianceOfficer: [SCHOOL_FLAGS.COMPLIANCE_V3_BETA] },
						},
					},
					{
						path: 'local-exchange',
						name: 'ComplianceSettingsLocalExchange',
						component: () => import('@/views/ComplianceSettingsLocalExchange.vue'),
						meta: {
							layout: 'LayoutAuthenticated',
							requiresAuth: true,
							title: 'pageComplianceSettingsLocalExchange.metaTitle',
							requiredAccess: complianceUserWithLocalExchangeEnabled,
							requiredFeatureFlags: { isComplianceOfficer: [SCHOOL_FLAGS.COMPLIANCE_V3_BETA] },
						},
					},
					{
						path: 'users',
						name: 'ComplianceSettingsUsers',
						component: () => import('@/views/ComplianceSettingsUsers.vue'),
						meta: {
							layout: 'LayoutAuthenticated',
							requiresAuth: true,
							title: 'pageComplianceSettingsUsers.metaTitle',
							requiredAccess: 'isComplianceOfficer',
							requiredFeatureFlags: { isComplianceOfficer: [SCHOOL_FLAGS.COMPLIANCE_V3_BETA] },
						},
					},
				],
			},
			{
				path: 'brands',
				name: 'ComplianceBrands',
				component: () => import('@/views/ComplianceBrands.vue'),
				meta: {
					requiresAuth: true,
					layout: 'LayoutAuthenticated',
					title: 'pageComplianceBrands.metaTitle',
					requiredAccess: complianceUserWithLocalExchangeEnabled,
					requiredFeatureFlags: { isComplianceOfficer: [SCHOOL_FLAGS.COMPLIANCE_V3_BETA] },
				},
			},
		],
	},
];

function complianceUserWithLocalExchangeEnabled() {
	const userStore = useUserStore();
	return isComplianceOfficerProfile(userStore.profile)
		&& userStore.profile.school.local_exchange_enabled;
}

function canAccessComplianceRoutes() {
	const userStore = useUserStore();
	if (!userStore.profileAccess.isComplianceOfficer) {
		return { name: 'NotFound' };
	}
}

function canAccessTransactions() {
	const userStore = useUserStore();
	if (!userStore.canAccessTransactions) {
		return { name: 'NotFound' };
	}
}

export default routes;
