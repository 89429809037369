<template>
	<Teleport to="body">
		<ul
			ref="toastsOverlay"
			class="toasts-overlay ma-4 reset-list"
		>
			<li
				v-for="toast in toastsVisible"
				:key="toast.uuid"
				ref="toast"
				:class="[
					'toasts-overlay__toast',
					`toasts-overlay__toast-${toast.uuid}`,
					{'--remove': toastsToRemove.includes(toast.uuid)}
				]"
			>
				<CommonToast
					elevation="6"
					height="92"
					:timeout="5000"
					:toast="toast"
					@close="removeToastLocal"
				/>
			</li>
		</ul>
	</Teleport>
</template>

<script lang="ts" setup>
import CommonToast from '@/components/CommonToast.vue';
import { useMainStore } from '@/stores/mainStore';
import type { Toast } from '@/composables/useToast';
import { computed, ref } from 'vue';

const props = withDefaults(defineProps<{
	maxVisibleToasts?: number;
}>(), {
	maxVisibleToasts: 3,
});

const { toasts, removeToast } = useMainStore();

const toastsToRemove = ref<string[]>([]);

const toastsVisible = computed<Toast[]>(() => {
	return toasts.slice(0, props.maxVisibleToasts);
});

function removeToastLocal(uuid: string) {
	const toastsVisibleIndex = toastsVisible.value.findIndex((toast) => toast.uuid === uuid);
	if (toastsVisibleIndex > -1) {
		toastsToRemove.value.push(uuid);
		setTimeout(() => {
			const index = toastsToRemove.value.findIndex((_uuid) => _uuid === uuid);
			if (index < 0) return;
			toastsToRemove.value.splice(1, index);
			removeToast(uuid);
		}, 250);
	} else {
		removeToast(uuid);
	}
}
</script>

<style lang="scss" scoped>
@use 'sass:list';
$toastsLength: v-bind('maxVisibleToasts');
$toastHeight: 92px;

@keyframes slide-in-up {
	0% {
		opacity: 0;
		transform: translateY(100%);
	}
	100% {
		opacity: 1;
		transform: translateY(0);
	}
}
@keyframes fade-out {
	0% {
		opacity: 1;
	}
	100% {
		opacity: 0;
	}
}

.toasts-overlay {
	position: fixed;
	bottom: 0;
	right: 0;
	z-index: 1000012;
	&__toast {
		animation: slide-in-up var(--tw-transition-duration);
		opacity: 1;
		transition: all ease-in-out var(--tw-transition-duration);
	}
}

@for $i from 0 through list.length($toastsLength) + 1 {
	.toasts-overlay__toast {
		&:nth-child(#{$i + 1}) {
			margin-bottom: 10px;
		}
		&.--remove {
			animation: fade-out var(--tw-transition-duration);
			opacity: 0;
		}
	}
}
</style>
