import { defineStore } from 'pinia';
import { computed, reactive, shallowRef, toRefs, watch } from 'vue';
import { i18n } from '@/plugins/i18n';
import {
	setLocalStorageItem,
	getLocalStorageItem,
	removeLocalStorageItem,
	EStorageKey,
} from '@/utils/localStorage';
import {
	useUserService,
} from '@/api/usersService';
import { useTermsOfUseService } from '@/api/termsOfUseService';
import { useFeatureFlagStore } from '@/stores/featureFlagsStore';
import { identifyUser, resetGainsight } from '@/plugins/gainsight';
import { usePusher } from '@/plugins/pusher';
import { clearDataDogUser, setDataDogUser } from '@/plugins/datadog';
import { handleUserChannelWebSocketNotification } from '@/utils/websocket';
import { useRouter, type RouteRecordName } from 'vue-router';
import { cloneDeep } from 'lodash-es';
import type { ExchangeConversationMessage } from '@/types/conversations';
import {
	ProfileAccessService,
} from '@/types/users';
import type { ServiceProvider } from '@/types/serviceProviders';
import {
	TRANSACTION_UNIT_TYPES,
	ROLES,
	type Role,
	type ApiV3,
} from '@teamworksdev/influencer-core';
import { canAccessRoute } from '@/router';
import { isImpersonating } from './authStore';

interface UseUserStoreState {
	user: ApiV3.User | undefined;
	impersonatedByUser: ApiV3.User | undefined;
	profile: ApiV3.UserProfile | undefined;
	inflcrId: string | null;
	inflcrImpersonatedById: string | null;
	maxAthletesPerBulkTransactionProposal: number;
	appSwitcherProfile: ApiV3.AppSwitcherUserProfile | undefined;
	serviceProvider: ServiceProvider | undefined;
}

interface ReceivedConversationEvent {
	conversationUuid: string;
	message: ExchangeConversationMessage;
	senderName: string;
}

export const useUserStore = defineStore('user', () => {
	const DEFAULT_STATE = {
		user: undefined,
		impersonatedByUser: undefined,
		profile: undefined,
		inflcrId: getLocalStorageItem<string>('inflcr-profile-id'),
		inflcrImpersonatedById: getLocalStorageItem<string>('inflcr-impersonated-by-profile-id'),
		maxAthletesPerBulkTransactionProposal: 275,
		appSwitcherProfile: undefined,
		serviceProvider: undefined,
	};

	const state = reactive<UseUserStoreState>(cloneDeep(DEFAULT_STATE));
	const router = useRouter();

	const subscribeToUserChannel = (userUuid: string) => {
		const pusher = usePusher();
		const channel = pusher.subscribe(`private-${import.meta.env.VITE_AWS_REGION}.user.${userUuid}`);
		channel.bind_global(handleUserChannelWebSocketNotification);
	};

	const receivedConversationEvent = shallowRef<ReceivedConversationEvent>();

	const setUser = async (impersonating: boolean = false) => {
		const userService = useUserService();
		const user = await userService.getUser();
		if (!user) throw new Error();
		state.user = user;
		if (!impersonating && !isImpersonating()) {
			subscribeToUserChannel(user.uuid);
		}
		setDataDogUser(`${import.meta.env.VITE_AWS_REGION}:${user.uuid}`);
	};

	const updateUser = (data: Partial<ApiV3.User>) => {
		if (!state.user) throw 'User not found';
		Object.assign(state.user, data);
	};

	const setInflcrId = (profileUuid: string) => {
		state.inflcrId = profileUuid;
		setLocalStorageItem<string>(
			EStorageKey.INFLCR_ID,
			profileUuid,
		);
	};

	const setInflcrImpersonatedById = (profileUuid: string) => {
		state.inflcrImpersonatedById = profileUuid;
		setLocalStorageItem<string>(
			EStorageKey.INFLCR_IMPERSONATED_BY_ID,
			profileUuid,
		);
	};

	const setImpersonatedUser = async () => {
		resetGainsight();
		const userService = useUserService();
		const user = await userService.getUser();
		if (!user) throw new Error();
		state.impersonatedByUser = state.user;
		setInflcrImpersonatedById(state.inflcrId!);
		state.user = user;
		state.profile = undefined;
		state.inflcrId = null;
		setDataDogUser(`${import.meta.env.VITE_AWS_REGION}:${user.uuid}`);
		removeLocalStorageItem('inflcr-profile-id');
	};

	const acceptTermsOfUse = async (version: string) => {
		const termsOfUseService = useTermsOfUseService();
		const status = await termsOfUseService.acceptTermsOfUse({ version });
		if (status !== 204) throw new Error();
		state.user!.terms_of_use.accepted = version;
	};

	const getProfileByUuid = (uuid: string): ApiV3.UserProfile | undefined => {
		const profile = userProfiles.value?.find((profile) => profile.uuid === uuid);
		if (!profile) throw new Error('Profile not found');
		return profile;
	};

	const setProfile = (uuid?: string) => {
		const _uuid = uuid ?? state.inflcrId;
		if (!_uuid) throw new Error('No profile uuid provided');
		state.profile = getProfileByUuid(_uuid);

		const { setFlagsForProfile } = useFeatureFlagStore();
		if (state.profile) setFlagsForProfile(state.profile);

		setInflcrId(_uuid);
		if (state.user) identifyUser(state.user, state.profile);
		setAppSwitcherUserProfile();
	};

	const setServiceProvider = (data: ServiceProvider | undefined) => {
		state.serviceProvider = data;
	};

	watch(() => state.profile, async () => {
		setServiceProvider(undefined);
	});

	const hasAppSwitcher = computed(() => {
		if (!state.profile) return false;

		const appSwitcherRoles: Role[] = [
			ROLES.TEAM_USER,
			ROLES.COMPLIANCE_OFFICER,
			ROLES.BRAND_USER,
			ROLES.AGENT,
		];

		return appSwitcherRoles.includes(state.profile.role_id);
	});

	const setAppSwitcherUserProfile = async () => {
		if (hasAppSwitcher.value) {
			const userService = useUserService({ bypassErrorToast: true });
			try {
				const appSwitcherProfile = await userService.getAppSwitcherUserProfile();
				state.appSwitcherProfile = appSwitcherProfile;
				mergeUserProfileLogoAndAppSwitcherProfileLogo(appSwitcherProfile);
			} catch {
				state.appSwitcherProfile = undefined;
			}
		} else {
			state.appSwitcherProfile = undefined;
		}
	};

	const mergeUserProfileLogoAndAppSwitcherProfileLogo = (appSwitcherProfile: ApiV3.AppSwitcherUserProfile) => {
		if (!appSwitcherProfile.navbar_image_url) return;
		const profile = state.user?.user_profiles.find((profile) => profile.uuid === appSwitcherProfile.profile_uuid);
		switch (profile?.role_id) {
			case ROLES.TEAM_USER:
				profile.academic_profile.team.logo = appSwitcherProfile.navbar_image_url;
				break;
			case ROLES.COMPLIANCE_OFFICER:
				profile.school.logo = appSwitcherProfile.navbar_image_url;
				break;
			default:
				break;
		}
	};

	const unsetUser = () => {
		state.profile = undefined;
		state.user = undefined;
		state.impersonatedByUser = undefined;
		state.inflcrId = null;
		state.inflcrImpersonatedById = null;
		state.appSwitcherProfile = undefined;
		removeLocalStorageItem('inflcr-profile-id');
		removeLocalStorageItem('inflcr-impersonated-by-profile-id');
		clearDataDogUser();
		receivedConversationEvent.value = undefined;
	};

	const userProfiles = computed<ApiV3.UserProfile[] | undefined>(() => state.user?.user_profiles);

	const impersonatedByProfile = computed<ApiV3.UserProfile | undefined>(() => {
		if (!state.impersonatedByUser || !state.inflcrImpersonatedById) return undefined;
		const profile = state.impersonatedByUser.user_profiles.find(
			(profile) => profile.uuid === state.inflcrImpersonatedById,
		);
		return profile;
	});

	const updateDefaultUserProfile = async (profileUuid: string) => {
		const userService = useUserService();
		const response = await userService.updateDefaultProfile(profileUuid);
		if (response !== 204) throw new Error();
		state.user?.user_profiles.forEach((profile) => {
			profile.is_default = profile.uuid === profileUuid;
		});
	};

	const refreshImpersonatedByUser = async () => {
		const userService = useUserService();
		const user = await userService.getUser(true);
		if (!user) throw new Error();
		state.impersonatedByUser = user;
	};

	const activeTeam = computed(() => {
		const activeProfile = state.profile;
		if (!activeProfile || !('academic_profile' in activeProfile)) return null;
		return activeProfile.academic_profile.team;
	});

	const hasUserAcceptedLatestTermsOfUse = computed<boolean>(() => (
		state.user?.terms_of_use.accepted === state.user?.terms_of_use.latest
	));

	const profileAccess = computed(() => new ProfileAccessService(state.profile));

	const profileCanAccessCurrentRoute = computed(() => {
		const currentRoute = router.currentRoute.value;
		return canAccessRoute(currentRoute.meta.requiredAccess, currentRoute.meta.requiredFeatureFlags);
	});

	const profileDefaultRoute = computed<RouteRecordName>(() => {
		const defaultRoutesByRole: Record<Role, RouteRecordName> = {
			[ROLES.SUPER_ADMINISTRATOR]: 'ReleaseNotesIndex',
			[ROLES.ADMINISTRATOR]: 'ReleaseNotesIndex',
			[ROLES.CLIENT_SUCCESS]: 'ReleaseNotesIndex',
			[ROLES.BRAND_USER]: 'ExchangeAthletes',
			[ROLES.AGENT]: 'ExchangeTransactions',
			[ROLES.TEAM_USER]: 'ContentHubIndex',
			[ROLES.COMPLIANCE_OFFICER]: 'DiscloseNilActivity',
		};
		return state.profile?.role_id ? defaultRoutesByRole[state.profile.role_id] : 'ReleaseNotesIndex';
	});

	const hasMultipleProfiles = computed<boolean>(() => {
		return (state.user?.user_profiles.length ?? 0) > 1;
	});

	const defaultProfile = computed(() => {
		return state.user?.user_profiles.find((profile) => profile.is_default);
	});

	const profileIsBrand = (profile: ApiV3.UserProfile | undefined): profile is ApiV3.BrandUserProfile => {
		return profile?.role_id === ROLES.BRAND_USER;
	};

	const profileIsAgent = (profile: ApiV3.UserProfile | undefined): profile is ApiV3.AgentProfile => {
		return profile?.role_id === ROLES.AGENT;
	};

	const brandProfile = computed<ApiV3.BrandUserProfile | undefined>(() => {
		return profileIsBrand(state.profile) ? state.profile : undefined;
	});

	const updateExchangeBrandProfile = (data: Partial<ApiV3.BrandUserProfile['exchange_brand']>) => {
		if (!profileIsBrand(state.profile)) throw 'Brand profile not found';
		Object.assign(state.profile.exchange_brand, data);
	};

	const entityType = computed<number | undefined>(() => {
		return brandProfile.value?.exchange_brand.entity_type ?? undefined;
	});

	const brandCanCreateBulletins = computed<boolean>(() => {
		if (!profileAccess.value.canAccessBulletins || !brandProfile.value) return false;
		const activeBulletinsAllowed = brandProfile.value.exchange_brand.active_bulletins_allowed;
		const activeBulletinsCount = brandProfile.value.exchange_brand.active_bulletins_count;
		return activeBulletinsAllowed === null || activeBulletinsCount < activeBulletinsAllowed;
	});

	const featuredBulletinsAllowed = computed<number>(() => {
		return brandProfile.value?.exchange_brand.max_number_of_featured_bulletins ?? 0;
	});

	const updateFeaturedBulletinsCount = (increment: boolean) => {
		const incrementValue = increment ? 1 : -1;
		if (brandProfile.value) brandProfile.value.exchange_brand.featured_bulletins_count += incrementValue;
	};

	const canAccessTransactions = computed<boolean>(() => {
		switch (state.profile?.role_id) {
			case ROLES.AGENT:
			case ROLES.BRAND_USER:
				return true;

			case ROLES.COMPLIANCE_OFFICER: {
				const featureFlagStore = useFeatureFlagStore();
				return featureFlagStore.isComplianceBetaEnabled;
			}

			default:
				return false;
		}
	});

	const transactionsRouteName = computed<string>(() => {
		switch (state.profile?.role_id) {
			case ROLES.AGENT:
			case ROLES.BRAND_USER:
				return 'ExchangeTransactions';

			case ROLES.COMPLIANCE_OFFICER:
				return 'ComplianceTransactions';

			default:
				return 'NotFound';
		}
	});

	const transactionRouteName = computed<string>(() => {
		switch (state.profile?.role_id) {
			case ROLES.AGENT:
			case ROLES.BRAND_USER:
				return 'ExchangeTransaction';

			case ROLES.COMPLIANCE_OFFICER:
				return 'ComplianceTransaction';

			default:
				return 'NotFound';
		}
	});

	const agreementRouteName = computed<string>(() => {
		switch (state.profile?.role_id) {
			case ROLES.AGENT:
			case ROLES.BRAND_USER:
				return 'ExchangeAgreement';

			case ROLES.COMPLIANCE_OFFICER:
				return 'ComplianceAgreement';

			default:
				return 'NotFound';
		}
	});

	const featuredBulletinsCount = computed<number>(() => {
		return brandProfile.value?.exchange_brand.featured_bulletins_count ?? 0;
	});

	const brandCanFeatureBulletins = computed<boolean>(() => {
		if (!profileAccess.value.canAccessBulletins || !brandProfile.value) return false;
		return featuredBulletinsCount.value < featuredBulletinsAllowed.value;
	});

	const maxAthletesPerBulkMessage = computed<number>(() => {
		return brandProfile.value?.exchange_brand.max_athletes_per_bulk_message ?? state.maxAthletesPerBulkTransactionProposal;
	});

	const paymentsReady = computed<boolean>(() => {
		if (!brandProfile.value) return false;
		return brandProfile.value.exchange_brand.payments_ready;
	});

	const isWalletVisible = computed<boolean>(() => {
		if (!brandProfile.value) return false;
		return brandProfile.value.exchange_brand.is_wallet_visible;
	});

	const leaderboardEnabled = computed<boolean>(() => {
		if (brandProfile.value) {
			return brandProfile.value.exchange_brand.leaderboard.enabled;
		} else if (profileIsAgent(state.profile)) {
			return profileAccess.value.hasLeaderboardRead;
		}
		return false;
	});

	const leaderboardUsesPoints = computed<boolean>(() => {
		if (brandProfile.value) {
			return brandProfile.value.exchange_brand.leaderboard.unit_type === TRANSACTION_UNIT_TYPES.POINTS;
		} else if (profileIsAgent(state.profile)) {
			return profileAccess.value.hasLeaderboardRead;
		}
		return false;
	});

	const newAgreementText = computed(() => {
		return !brandProfile.value || leaderboardEnabled.value
			? i18n.global.t('proposeTransaction')
			: i18n.global.t('proposeAgreement');
	});

	const $reset = () => {
		Object.assign(state, cloneDeep(DEFAULT_STATE));
	};

	return {
		...toRefs(state),
		setUser,
		updateUser,
		setImpersonatedUser,
		setProfile,
		setServiceProvider,
		setInflcrId,
		unsetUser,
		acceptTermsOfUse,
		userProfiles,
		activeTeam,
		hasUserAcceptedLatestTermsOfUse,
		profileAccess,
		profileDefaultRoute,
		profileCanAccessCurrentRoute,
		impersonatedByProfile,
		refreshImpersonatedByUser,
		subscribeToUserChannel,
		receivedConversationEvent,
		brandProfile,
		entityType,
		brandCanCreateBulletins,
		brandCanFeatureBulletins,
		maxAthletesPerBulkMessage,
		featuredBulletinsAllowed,
		featuredBulletinsCount,
		updateFeaturedBulletinsCount,
		canAccessTransactions,
		transactionsRouteName,
		transactionRouteName,
		agreementRouteName,
		paymentsReady,
		updateExchangeBrandProfile,
		isWalletVisible,
		leaderboardEnabled,
		leaderboardUsesPoints,
		newAgreementText,
		profileIsAgent,
		updateDefaultUserProfile,
		defaultProfile,
		hasMultipleProfiles,
		$reset,
	};
});
