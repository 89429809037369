import type { ApiV3 } from '@teamworksdev/influencer-core';
import { getList, post, isApiError } from './utilities/provider';
import type { AxiosRequestConfig } from 'axios';

const RESOURCE = 'terms-of-use';

export function useTermsOfUseService(config?: Omit<AxiosRequestConfig, 'key'>) {
	const getTermsOfUse = async () => {
		const response = await getList<ApiV3.TermsOfUseResponse>(RESOURCE, {
			key: 'getTermsOfUse',
			requiresInflcrId: false,
			requiresAcceptedTou: false,
			...config,
		});
		if (isApiError(response)) throw response;
		return response.data.data;
	};

	const acceptTermsOfUse = async (model: ApiV3.TermsOfUseRequest) => {
		const response = await post(`${RESOURCE}/accept`, model, <AxiosRequestConfig>{
			requiresInflcrId: false,
			requiresAcceptedTou: false,
			...config,
		});
		if (isApiError(response)) throw response;
		return response.status;
	};

	return {
		getTermsOfUse,
		acceptTermsOfUse,
	};
}
