import { useUserStore } from '@/stores/userStore';
import { useMainStore } from '@/stores/mainStore';
import { i18n } from '@/plugins/i18n';
import type { Toast, ToastType } from '@/composables/useToast';
import { camelCase } from 'lodash-es';
import { ExchangeConversationMessage } from '@/types/conversations';
import { type ApiV3 } from '@teamworksdev/influencer-core';

export const EVENT_KEYS = [
	'exchange.transaction.create.completed',
	'gallery.duplicate.complete',
	'gallery.duplicate.failed',
	'media.delete.complete',
	'media.delete.failed',
	'media.download.progress',
	'media.update.failed',
	'conversation.comment.created',
] as const;
export type EventKey = typeof EVENT_KEYS[number];

export const DOWNLOAD_STATUSES = {
	pending: 0,
	in_progess: 1,
	finished: 2,
	failed: 3,
} as const;
type DownloadStatus = keyof typeof DOWNLOAD_STATUSES;

interface EventTranslation {
	key: EventKey | string;
	data?: Record<string, unknown>;
}

interface EventBase {
	metadata: EventMetaData;
	data: any;
}

interface EventMetaData {
	profile_context: string;
	job_id?: string;
}

interface EventExchangeTransactionCreateCompleted extends EventBase {
	eventKey: 'exchange.transaction.create.completed';
	data: Record<string, never>;
}

interface EventExchangeTransactionCreateCompleted extends EventBase {
	eventKey: 'exchange.transaction.create.completed';
	data: Record<string, never>;
}

interface EventGalleryDuplicateComplete extends EventBase {
	eventKey: 'gallery.duplicate.complete';
	data: {
		source: string;
		destination: string;
	};
}

interface EventGalleryDuplicateFailed extends EventBase {
	eventKey: 'gallery.duplicate.failed';
	data: {
		source: string;
		destination: string;
	};
}

interface EventMediaDeleteComplete extends EventBase {
	eventKey: 'media.delete.complete';
	data: {
		items: number;
	};
}

interface EventMediaDeleteFailed extends EventBase {
	eventKey: 'media.delete.failed';
	data: {
		items: number;
	};
}

interface EventMediaUpdateFailed extends EventBase {
	eventKey: 'media.update.failed';
	data: {
		items: number;
	};
}

interface EventMediaDownloadProgress extends EventBase {
	eventKey: 'media.download.progress';
	data: {
		id: string;
		name: string;
		message: string;
		status: DownloadStatus;
		progress: {
			current: number;
			total: number;
		};
	};
}

export interface EventConversationCommentCreated extends EventBase {
	eventKey: 'conversation.comment.created';
	data: {
		conversation_uuid: string;
		message: ApiV3.Message;
		sender: {
			first_name: string;
			last_name: string;
		};
	};
}

type WebsocketEvent =
	| EventExchangeTransactionCreateCompleted
	| EventGalleryDuplicateComplete
	| EventGalleryDuplicateFailed
	| EventMediaDeleteComplete
	| EventMediaDeleteFailed
	| EventMediaUpdateFailed
	| EventMediaDownloadProgress
	| EventConversationCommentCreated;

const displayToast = (translation: EventTranslation, type: ToastType = 'success', link?: string) => {
	const { setToast } = useMainStore();
	const translationKey = camelCase(translation.key);
	const titlePath = `websocketNotifications.${translationKey}.title`;
	const descriptionPath = `websocketNotifications.${translationKey}.description`;
	const descriptionExists = i18n.global.te(descriptionPath);

	const toast: Omit<Toast, 'uuid'> = {
		description: descriptionExists
			? i18n.global.t(descriptionPath, { ...translation.data })
			: undefined,
		title: i18n.global.t(titlePath, { ...translation.data }),
		type,
		link,
	};

	setToast(toast);
};

const handleMediaDownloadProgress = (event: EventMediaDownloadProgress) => {
	const downloadStatusToasts: Partial<Record<DownloadStatus, () => void>> = {
		finished: () => displayToast({ key: 'mediaDownloadComplete' }),
		failed: () => displayToast({ key: 'mediaDownloadFailed' }, 'error'),
	};

	downloadStatusToasts[event.data.status]?.();
};

const handleConversationCommentCreated = (event: EventConversationCommentCreated) => {
	const userStore = useUserStore();
	userStore.receivedConversationEvent = {
		conversationUuid: event.data.conversation_uuid,
		message: new ExchangeConversationMessage(event.data.message),
		senderName: `${event.data.sender.first_name} ${event.data.sender.last_name}`,
	};
};

const handleWebsocketEvent = (event: WebsocketEvent) => {
	switch (event.eventKey) {
		case 'gallery.duplicate.complete':
		case 'media.delete.complete':
		case 'exchange.transaction.create.completed':
			return displayToast({ key: event.eventKey });

		case 'gallery.duplicate.failed':
		case 'media.delete.failed':
		case 'media.update.failed':
			return displayToast({ key: event.eventKey }, 'error');

		case 'media.download.progress':
			return handleMediaDownloadProgress(event);

		case 'conversation.comment.created':
			return handleConversationCommentCreated(event);
	}
};

export const handleUserChannelWebSocketNotification = (eventName: string, data: EventBase) => {
	const userStore = useUserStore();
	if (!userStore.profile?.uuid || data.metadata?.profile_context !== userStore.profile?.uuid) return;

	const eventKey = EVENT_KEYS.find((e) => e === eventName);
	if (!eventKey) return;

	handleWebsocketEvent({ ...data, eventKey });
};
