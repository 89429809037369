import { ROLES, type ApiV3 } from '@teamworksdev/influencer-core';
import { isAuthenticated } from '@/stores/authStore';
import { get } from 'lodash';

declare global {
	interface Window {
		aptrinsic?: any;
	}
}

const EVENTS = {
	UPLOAD_COMPLETE: 'Direct Upload Complete',
	IMPORT_QUEUED: 'Content Integration Import Queued',
	GALLERY_DUPLICATED: 'Gallery Duplicated',
	MEDIA_UPDATED: 'Media Updated',
	ATHLETE_EXCHANGE_PROFILE_VIEWED: 'Athlete Exchange Profile Viewed',
	USER_LIST_CREATED: 'User List Created',
	EXCHANGE_BULLETIN_CREATED: 'Exchange Bulletin Created',
	PAYMENT_METHOD_ADDED: 'Payment Method Added',
	EXCHANGE_MESSAGE_JOB_TRIGGERED: 'Exchange Message Job Triggered',
	EXCHANGE_PAYMENT_SENT: 'Exchange Payment Sent',
	EXCHANGE_TRANSACTION_JOB_TRIGGERED: 'Exchange Transaction Job Triggered',
	WALLET_BRAND_ONBOARDING_COMPLETE: 'Wallet Brand Onboarding Complete',
	WALLET_PAYMENT_SENT: 'Wallet Payment Sent',
	BULK_PAYMENT_SENT: 'Bulk Payment Sent',
	PROVIDER_CONTACT_INFO_SETTING_CHANGED: 'Provider Adjusted Contact Info Visibility',
	PROVIDER_REVIEWS_ACCEPTANCE_SETTING_CHANGED: 'Provider Adjusted Reviews Acceptance Setting',
	TRANSACTION_IMPORT_JOB_TRIGGERED: 'Transaction Import Job Triggered',
} as const;
type Event = typeof EVENTS[keyof typeof EVENTS];
type EventMetadata = Record<string, string | number | boolean | null | undefined>;
const initGainsight = () => {
	if (import.meta.env.MODE === 'development') return;
	const existingScript = document.getElementById('gainsight-script');
	if (existingScript) return;
	const gainsightScript = document.createElement('script');
	gainsightScript.setAttribute('id', 'gainsight-script');
	gainsightScript.innerHTML = `(function(n,t,a,e){var i="aptrinsic";n[i]=n[i]||function(){  
        (n[i].q=n[i].q||[]).push(arguments)},n[i].p=e; 
        var r=t.createElement("script");r.async=!0,r.src=a+"?a="+e;
        var c=t.getElementsByTagName("script")[0];c.parentNode.insertBefore(r,c) 
      })(window,document,"https://web-sdk.aptrinsic.com/api/aptrinsic.js","${import.meta.env.VITE_GAINSIGHT_PX_APP_ID}");`;
	document.body.appendChild(gainsightScript);
};

const identifyUser = (user: ApiV3.User, profile?: ApiV3.UserProfile) => {
	if (!isAuthenticated()) return;
	window.aptrinsic?.('identify',
		{
			id: get(profile, 'academic_profile.axle.user_id', user.uuid),
			email: user.email,
			firstName: user.first_name,
			lastName: user.last_name,
			INFLCRGroup: get(profile, 'academic_profile.group.name'),
			INFLCRRole: profile?.role_id ? Object.keys(ROLES).find((key) => profile?.role_id === ROLES[key as keyof typeof ROLES]) : undefined,
			INFLCRBrandName: get(profile, 'exchange_brand.name'),
			teamName: get(profile, 'academic_profile.team.name'),
			isGlobal: get(profile, 'exchange_brand.is_global'),
		},
		{
			id: get(profile, 'academic_profile.axle.org_id', `${user.first_name} ${user.last_name}`),
		},
	);
};

const resetGainsight = () => {
	window.aptrinsic?.('reset');
};

const trackEvent = (eventName: Event, metadata?: EventMetadata) => {
	window.aptrinsic?.('track', eventName, metadata);
};

export {
	EVENTS,
	initGainsight,
	identifyUser,
	trackEvent,
	resetGainsight,
};
