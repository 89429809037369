import { ref, reactive, watch } from 'vue';
import type { ApiV3 } from '@teamworksdev/influencer-core';

interface UseBulletinDrawerState {
	activeBulletinUuid: string | undefined;
	isOpen: boolean;
	onApplied: (() => void) | undefined;
	onPreviewMedia: ((payload: { index: number; media: ApiV3.Media[] }) => void) | undefined;
}

const DEFAULT_STATE: UseBulletinDrawerState = {
	activeBulletinUuid: undefined,
	isOpen: false,
	onApplied: undefined,
	onPreviewMedia: undefined,
} as const;

const getDefaultState = () => ({ ...DEFAULT_STATE });

const state = reactive<UseBulletinDrawerState>(getDefaultState());

function resetState() {
	Object.assign(state, getDefaultState());
}

const isRendered = ref<boolean>(false);

watch(() => state.isOpen, (isOpen) => {
	if (isOpen && !isRendered.value) isRendered.value = true;
	if (!isOpen) resetState();
});

interface UseBulletinDrawerOptions {
	onApplied?: () => void;
	onPreviewMedia?: (payload: { index: number; media: ApiV3.Media[] }) => void;
}

export function useBulletinDrawer(options?: UseBulletinDrawerOptions) {
	async function open(uuid?: string) {
		state.isOpen = true;
		state.activeBulletinUuid = uuid;
		state.onApplied = () => closeWithCallback(options?.onApplied);
		state.onPreviewMedia = options?.onPreviewMedia;
	}

	function close() {
		state.isOpen = false;
	}

	function closeWithCallback(fn: (() => void) | undefined) {
		close();
		fn?.();
	}

	return {
		state,
		isRendered,
		resetState,
		open,
		close,
	};
}
