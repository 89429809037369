import {
	type ApiV3,
} from '@teamworksdev/influencer-core';

const isMediaGallery = (media: ApiV3.Media | ApiV3.MediaGallery): media is ApiV3.MediaGallery => {
	return 'gallery' in media;
};


export {
	isMediaGallery,
};
