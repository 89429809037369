import { createRouter, createWebHistory, type LocationQuery, type LocationQueryValue } from 'vue-router';
import routes from './routes';
import { isAuthenticated } from '@/stores/authStore';
import { useUserStore } from '@/stores/userStore';
import { useFeatureFlagStore } from '@/stores/featureFlagsStore';
import { useMainStore } from '@/stores/mainStore';
import { isImpersonating, useAuthStore } from '@/stores/authStore';
import type { ProfileAccessService } from '@/types/users';
import type { ApiV3 } from '@teamworksdev/influencer-core';
import { useAuthService } from '@/api/authService';
import { useBasicAuth } from '@/composables/useBasicAuth';
import { isEmpty } from 'lodash-es';
import { add } from 'date-fns';
import type { RouteMeta } from 'vue-router';
import type { AnyFlag } from '@teamworksdev/influencer-core';
import { normalizeToArray } from '@/utils/helpers';
import qs from 'qs';

declare module 'vue-router' {
	interface RouteMeta {
		layout: 'LayoutLogin' | 'LayoutAuthenticated' | 'LayoutDefault' | 'LayoutExchangeSchoolSite';
		layoutOptions?: Record<string, unknown>;
		title?: string;
		requiresAuth: boolean;
		requiredAccess?: keyof ProfileAccessService | (() => boolean) | (keyof ProfileAccessService)[];
		profileSwitchRedirect?: RouteRecordName | (() => RouteRecordName);
		requiredFeatureFlags?: MaybeArray<AnyFlag> | { [K in keyof ProfileAccessService]?: MaybeArray<AnyFlag> };
	}
}

const router = createRouter({
	history: createWebHistory(import.meta.env.BASE_URL),
	routes,
	scrollBehavior(to) {
		if (to.hash) {
			return new Promise((resolve) => {
				setTimeout(() => {
					resolve({
						el: to.hash,
						behavior: 'smooth',
					});
				}, 500);
			});
		}
	},
	parseQuery: (query) => qs.parse(query, { decoder: (str, decoder, charset) => {
		const strWithoutPlus = str.replace(/\+/g, ' ');
		if (charset === 'iso-8859-1') {
			return strWithoutPlus.replace(/%[0-9a-f]{2}/gi, function (match) {
				try {
					return decodeURIComponent(match);
				} catch {
					return match;
				}
			});
		}

		if (/^(\d+|\d*\.\d+)$/.test(str)) {
			return parseFloat(str);
		}

		const keywords: { [key: string]: boolean | null | undefined } = {
			true: true,
			false: false,
			null: null,
			undefined: undefined,
		};
		if (str in keywords) {
			return keywords[str];
		}

		// utf-8
		try {
			return decodeURIComponent(strWithoutPlus);
		} catch {
			return strWithoutPlus;
		}
	} }) as LocationQuery,
});

async function hasUser(): Promise<boolean> {
	const userStore = useUserStore();
	const featureFlagsStore = useFeatureFlagStore();
	if (userStore.user) return true;

	const mainStore = useMainStore();
	mainStore.isAppLoading = true;

	try {
		await userStore.setUser();
		await featureFlagsStore.fetchFeatureFlags();
		return true;
	} catch (e) {
		console.error(e);
		return false;
	}
}

function hasProfile(): boolean {
	const userStore = useUserStore();
	if (userStore.profile) return true;

	const mainStore = useMainStore();
	mainStore.isAppLoading = true;

	try {
		userStore.setProfile();
		return true;
	} catch {
		return false;
	}
}

async function hasImpersonatedByUser(): Promise<boolean> {
	if (!isImpersonating()) return true;

	const userStore = useUserStore();

	if (userStore.impersonatedByProfile) return true;

	const mainStore = useMainStore();
	mainStore.isAppLoading = true;

	try {
		await userStore.refreshImpersonatedByUser();
		if (!userStore.impersonatedByProfile) throw new Error();
		return true;
	} catch {
		return false;
	}
}

function resolveRequiredAccess(requiredAccess: RouteMeta['requiredAccess']): boolean {
	const userStore = useUserStore();
	if (!requiredAccess) return true;
	return typeof requiredAccess === 'function' ? requiredAccess() : normalizeToArray(requiredAccess).some((k) => userStore.profileAccess[k]);
}

function resolveFeatureFlagAccess(featureFlags: RouteMeta['requiredFeatureFlags']): boolean {
	const userStore = useUserStore();
	const featureFlagStore = useFeatureFlagStore();
	if (!featureFlags) return true;
	return Array.isArray(featureFlags) || typeof featureFlags === 'string'
		? normalizeToArray(featureFlags).every((flag) => featureFlagStore.isFlagEnabled(flag))
		: Object.keys(featureFlags).every((k) => {
			if (!userStore.profileAccess[k as keyof ProfileAccessService]) return true;
			return normalizeToArray(featureFlags[k as keyof typeof featureFlags]).every((flag) => {
				return featureFlagStore.isFlagEnabled(flag!);
			});
		});
}

export function canAccessRoute(requiredAccess: RouteMeta['requiredAccess'], featureFlag: RouteMeta['requiredFeatureFlags']): boolean {
	if (requiredAccess && !resolveRequiredAccess(requiredAccess)) return false;
	if (featureFlag && !resolveFeatureFlagAccess(featureFlag)) return false;
	return true;
}

async function setAuthTokenFromQuery(token: LocationQueryValue | LocationQueryValue[]) {
	if (!token || Array.isArray(token)) return false;
	const { introspectToken } = useAuthService();
	const authStore = useAuthStore();

	const authToken: ApiV3.AuthToken = {
		uuid: crypto.randomUUID(),
		access_token: token,
		expires_at: add(new Date(), { days: 1 }).toISOString(),
	};

	authStore.setToken(authToken, true);

	try {
		const _token = await introspectToken();
		authStore.setToken(_token, true);
		return true;
	} catch (error) {
		console.error(error);
		return false;
	}
}

function setInflcrId(profileUuid: LocationQueryValue | LocationQueryValue[]) {
	if (!profileUuid || Array.isArray(profileUuid)) return false;
	const userStore = useUserStore();
	userStore.setInflcrId(profileUuid as string);
}

router.beforeEach(async (to, from) => {
	const userStore = useUserStore();

	if (
		to.query.token
		&& to.name !== 'LoginAxle'
		&& to.name !== 'LoginResetPassword'
	) await setAuthTokenFromQuery(to.query.token);

	if (to.query.profileUuid) setInflcrId(to.query.profileUuid);

	if (to.meta.requiresAuth) {
		if (!isAuthenticated() || !await hasUser() || !await hasImpersonatedByUser()) {
			const { logOut } = useBasicAuth();
			await logOut({ redirect: false });
			const redirect = window.location.pathname;
			return { name: 'LoginIndex', query: { redirect } };
		}

		if (to.name !== 'ProfileChooserIndex' && !hasProfile()) {
			return { name: 'ProfileChooserIndex' };
		}

		if (to.name === 'ProfileChooserIndex' && hasProfile()) {
			return from.name && from.meta.requiresAuth ? false : { name: userStore.profileDefaultRoute };
		}

		if (to.meta.requiredAccess || to.meta.requiredFeatureFlags) {
			const requiredAccess = canAccessRoute(to.meta.requiredAccess, to.meta.requiredFeatureFlags);
			if (!requiredAccess) {
				return { name: 'NoAccess' };
			}
		}
	}
});

router.afterEach((to, from) => {
	if (
		isEmpty(from.name)
		&& to.name !== 'LoginAxle'
		&& to.name !== 'LoginResetPassword'
		&& (to.query.token || to.query.profileUuid)
	) router.replace({
		query: {
			...to.query,
			token: undefined,
			profileUuid: undefined,
		},
	});

	const mainStore = useMainStore();
	mainStore.isAppLoading = false;
});

router.onError((error, to) => {
	if (error.message.includes('Failed to fetch dynamically imported module') || error.message.includes('Failed to load module script')) {
		(window as Window).location = to.fullPath;
	}
});

export default router;
