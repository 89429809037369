import { computed, ref, reactive, watch, unref, type MaybeRef } from 'vue';
import type { ApiV3 } from '@teamworksdev/influencer-core';
import { useBulletinAsync } from '@/composables/useBulletinAsync';

interface UseBulletinCrudModalState {
	isOpen: boolean;
	isEditing?: boolean;
	bulletinUuid?: string;
	activeBulletin: ApiV3.SingleExchangeBulletin | undefined;
	onBulletinUpdated: (() => void) | undefined;
	selectedAthletes?: Uuid[];
}

const DEFAULT_STATE: UseBulletinCrudModalState = {
	isOpen: false,
	isEditing: false,
	activeBulletin: undefined,
	onBulletinUpdated: undefined,
	selectedAthletes: [],
} as const;

const getDefaultState = () => ({ ...DEFAULT_STATE });

const state = reactive<UseBulletinCrudModalState>(getDefaultState());

function resetState() {
	Object.assign(state, getDefaultState());
}

const isRendered = ref<boolean>(false);

watch(() => state.isOpen, (isOpen) => {
	if (isOpen && !isRendered.value) isRendered.value = true;
	if (!isOpen) resetState();
});

interface UseBulletinCrudModalOptions {
	selectedAthletes?: MaybeRef<Uuid[]>;
	onUpdated?: () => void;
}

export function useBulletinCrudModal(options?: UseBulletinCrudModalOptions) {
	const {
		bulletin,
		getBulletin,
	} = useBulletinAsync(computed(() => state.bulletinUuid), { redirectOnError: false });

	async function open(bulletinOrUuid?: ApiV3.SingleExchangeBulletin | string) {
		state.isOpen = true;
		state.isEditing = !!bulletinOrUuid;
		state.onBulletinUpdated = options?.onUpdated;
		state.selectedAthletes = unref(options?.selectedAthletes);
		if (typeof bulletinOrUuid === 'string') {
			state.bulletinUuid = bulletinOrUuid;
			await getBulletin();
			state.activeBulletin = bulletin.value ?? undefined;
		} else {
			state.activeBulletin = bulletinOrUuid;
		}
	}

	return {
		state,
		isRendered,
		resetState,
		open,
	};
}
