import { reactive } from 'vue';
import { useAuthStore } from '@/stores/authStore';
import type { ApiV3 } from '@teamworksdev/influencer-core';

interface UseAxleAuthOptions {
	onLogInSuccess?: () => void;
	onLogInError?: () => void;
}

export function useAxleAuth(options?: UseAxleAuthOptions) {
	const authStore = useAuthStore();

	const defaultAxleTokenRequest: ApiV3.AxleAuthRequest = {
		token: '',
	};

	const axleTokenRequest: ApiV3.AxleAuthRequest = reactive({ ...defaultAxleTokenRequest });

	function reset() {
		Object.assign(axleTokenRequest, { ...defaultAxleTokenRequest });
	}

	function handleSuccess() {
		if (options?.onLogInSuccess !== undefined) {
			options.onLogInSuccess();
		}
	}

	function handleError() {
		reset();
		if (options?.onLogInError !== undefined) {
			options.onLogInError();
		}
	}

	async function logInAxle() {
		try {
			await authStore.getTokenAxle(axleTokenRequest);
			handleSuccess();
		} catch (e) {
			console.error(e);
			handleError();
		}
	}

	return {
		axleTokenRequest,
		logInAxle,
	};
}
