import {
	type SingleExchangeAthlete,
} from '@/types/exchangeAthletes';
import { type ApiV3 } from '@teamworksdev/influencer-core';
import { cloneDeep, isEqual } from 'lodash-es';
import { defineStore } from 'pinia';
import { computed, reactive, toRefs } from 'vue';

export type ExchangeAthletesFilterParams = Omit<ApiV3.GetExchangeAthletesRequestParamsRequired, 'name' | 'page' | 'sort'>;

interface UseExchangeAthletesStoreState {
	selectedAthletes: SingleExchangeAthlete[];
	athletesFilters: ExchangeAthletesFilterParams;
	athletesSearch: string | null;
}

export const useExchangeAthletesStore = defineStore('exchange-athletes', () => {
	const DEFAULT_ATHLETES_REQUEST_FILTERS: ExchangeAthletesFilterParams = {
		sports: [],
		schools: [],
		conferences: [],
		academic_years: [],
		interests: [],
		show_social: null,
		social_providers: {
			instagram: {
				has_account: null,
				follower_count: {
					operator: null,
					value: null,
				},
			},
			twitter: {
				has_account: null,
				follower_count: {
					operator: null,
					value: null,
				},
			},
			tiktok: {
				has_account: null,
				follower_count: {
					operator: null,
					value: null,
				},
			},
		},
		list: [],
	};

	const DEFAULT_STATE = {
		selectedAthletes: [],
		athletesFilters: cloneDeep(DEFAULT_ATHLETES_REQUEST_FILTERS),
		athletesSearch: '',
	};

	const state = reactive<UseExchangeAthletesStoreState>(cloneDeep(DEFAULT_STATE));

	const setSelectedAthletes = (athletes: SingleExchangeAthlete[]) => {
		state.selectedAthletes = athletes;
	};

	const setAthletesFilters = (filters: ExchangeAthletesFilterParams) => {
		Object.assign(state.athletesFilters, cloneDeep(filters));
	};

	const setAthletesSearch = (search: string) => {
		state.athletesSearch = search || null;
	};

	const resetAthletesFilters = () => {
		Object.assign(state.athletesFilters, cloneDeep(DEFAULT_ATHLETES_REQUEST_FILTERS));
	};

	const athletesRequestParams = computed<Omit<ApiV3.GetExchangeAthletesRequestParamsRequired, 'page'>>(() => {
		const name = state.athletesSearch ? state.athletesSearch : null;
		return {
			...state.athletesFilters,
			name,
		};
	});

	const hasActiveFilters = computed<boolean>(() => {
		return !isEqual(state.athletesFilters, DEFAULT_ATHLETES_REQUEST_FILTERS);
	});

	const selectedAthletesUuids = computed(() => {
		return state.selectedAthletes.map((athlete) => athlete.uuid);
	});

	const $reset = () => {
		Object.assign(state, cloneDeep(DEFAULT_STATE));
	};

	return {
		...toRefs(state),
		selectedAthletesUuids,
		setSelectedAthletes,
		setAthletesFilters,
		setAthletesSearch,
		resetAthletesFilters,
		athletesRequestParams,
		hasActiveFilters,
		DEFAULT_ATHLETES_REQUEST_FILTERS,
		$reset,
	};
});
