import axios from 'axios';
import type { AxiosRequestConfig } from 'axios';
import { handleResponse, handleError } from './response';
import { type ApiErrorResponse } from '@teamworksdev/influencer-core';
import { useMock } from './mock';

if (import.meta.env.MODE !== 'production') useMock(axios);

export const isApiError = (response: unknown): response is ApiErrorResponse => {
	return typeof response === 'object' && response !== null && 'message' in response;
};

const getRequestUrl = (resource: string, id?: string) => {
	const url = `/${resource}`;
	return id ? url + `/${id}` : url;
};

const getList = async <T = any, U = any>(
	resource: string,
	config?: AxiosRequestConfig,
) => {
	try {
		const response = await axios.get<T>(getRequestUrl(resource), config);
		return handleResponse<T>(response);
	} catch (error) {
		return handleError<U>(error);
	}
};

const getSingle = async <T = any, U = any>(
	resource: string,
	id: string,
	config?: AxiosRequestConfig,
) => {
	try {
		const response = await axios.get<T>(getRequestUrl(resource, id), config);
		return handleResponse<T>(response);
	} catch (error) {
		return handleError<U>(error);
	}
};

const post = async <T = any, U = any>(
	resource: string,
	model: Record<string, any>,
	config?: AxiosRequestConfig,
) => {
	try {
		const response = await axios.post<T>(getRequestUrl(resource), model, config);
		return handleResponse<T>(response);
	} catch (error) {
		return handleError<U>(error);
	}
};

const put = async <T = any, U = any>(
	resource: string,
	id: string | undefined,
	model: Record<string, any>,
	config?: AxiosRequestConfig,
) => {
	try {
		const response = await axios.put<T>(getRequestUrl(resource, id), model, config);
		return handleResponse<T>(response);
	} catch (error) {
		return handleError<U>(error);
	}
};

const patch = async <T = any, U = any>(
	resource: string,
	id: string,
	model?: Record<string, any>,
	config?: AxiosRequestConfig,
) => {
	try {
		const response = await axios.patch<T>(getRequestUrl(resource, id), model, config);
		return handleResponse<T>(response);
	} catch (error) {
		return handleError<U>(error);
	}
};

const patchWithoutId = async <T = any, U = any>(
	resource: string,
	model?: Record<string, any>,
	config?: AxiosRequestConfig,
) => {
	try {
		const response = await axios.patch<T>(getRequestUrl(resource), model, config);
		return handleResponse<T>(response);
	} catch (error) {
		return handleError<U>(error);
	}
};

const patchMany = async <T = any, U = any>(
	resource: string,
	model: Record<string, any>,
	config?: AxiosRequestConfig,
) => {
	try {
		const response = await axios.patch<T>(getRequestUrl(resource), model, config);
		return handleResponse<T>(response);
	} catch (error) {
		return handleError<U>(error);
	}
};

const remove = async <T = null, U = any>(
	resource: string,
	id: string,
	config?: AxiosRequestConfig,
) => {
	try {
		const response = await axios.delete<T>(getRequestUrl(resource, id), config);
		return handleResponse<T>(response);
	} catch (error) {
		return handleError<U>(error);
	}
};


const removeMany = async <T = any, U = any>(
	resource: string,
	config?: AxiosRequestConfig,
) => {
	try {
		const response = await axios.delete<T>(getRequestUrl(resource), config);
		return handleResponse<T>(response);
	} catch (error) {
		return handleError<U>(error);
	}
};

export {
	getList,
	getSingle,
	post,
	put,
	patch,
	patchMany,
	patchWithoutId,
	remove,
	removeMany,
};
