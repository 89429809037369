import { i18n } from '@/plugins/i18n';
import { PAYMENT_GATEWAYS, STRIPE_ONBOARDING_STATUSES, WALLET_ONBOARDING_STATUSES } from '@teamworksdev/influencer-core';
import { camelCase } from 'lodash-es';

const paymentGateways = Object.keys(PAYMENT_GATEWAYS).map((key) => ({
	id: PAYMENT_GATEWAYS[key as keyof typeof PAYMENT_GATEWAYS],
	name: i18n.global.t(`paymentGateways.${camelCase(key)}`),
}));

const stripeOnboardingStatuses = Object.keys(STRIPE_ONBOARDING_STATUSES).map((key) => ({
	id: STRIPE_ONBOARDING_STATUSES[key as keyof typeof STRIPE_ONBOARDING_STATUSES],
	name: i18n.global.t(`stripeOnboardingStatuses.${camelCase(key)}.title`),
	tooltip: undefined,
}));

const walletOnboardingStatuses = Object.keys(WALLET_ONBOARDING_STATUSES).map((key) => ({
	id: WALLET_ONBOARDING_STATUSES[key as keyof typeof WALLET_ONBOARDING_STATUSES],
	name: i18n.global.t(`walletOnboardingStatuses.${camelCase(key)}.title`),
	tooltip: undefined,
}));

export {
	paymentGateways,
	stripeOnboardingStatuses,
	walletOnboardingStatuses,
};
