import { useUserStore } from '@/stores/userStore';
import { exchangeStatuses } from '@/utils/transactions';
import { isAfter } from 'date-fns';
import type { ApiV3 } from '@teamworksdev/influencer-core';
import { agreementStatuses } from '@/utils/agreements';

interface ExchangeConversation {
	uuid: string;
	fullName: string;
	profileImage: string | null;
	teamName: string | null;
	isContactDetailsShared: boolean;
	isReadOnly: boolean;
	athleteUuid: string;
	message?: ExchangeConversationMessage;
	isNew?: boolean;
	isDelivered?: boolean;
	isRead?: boolean;
}

class SingleExchangeConversation implements ExchangeConversation {
	uuid: string;
	fullName: string;
	profileImage: string | null;
	teamName: string | null;
	isReadOnly: boolean;
	isContactDetailsShared: boolean;
	athleteUuid: string;

	constructor(data: ApiV3.ExchangeConversation | ApiV3.SingleExchangeConversation) {
		this.uuid = data.uuid;
		this.uuid = data.uuid;
		this.fullName = `${data.receiving_user.first_name} ${data.receiving_user.last_name}`;
		this.profileImage = data.receiving_user.profile_image;
		this.teamName = data.receiving_user.team;
		this.isContactDetailsShared = data.athlete_has_shared_contact_details;
		this.isReadOnly = data.read_only;
		this.athleteUuid = data.receiving_user.uuid;
	}
}

class ExchangeConversationListItem extends SingleExchangeConversation {
	isNew: boolean;
	isDelivered: boolean;
	isRead: boolean;
	message: ExchangeConversationMessage;

	constructor(data: ApiV3.ExchangeConversation) {
		super(data);
		this.message = new ExchangeConversationMessage(data.message);

		const userStore = useUserStore();
		const lastMessageSentByUser = data.message.user.uuid === userStore.user?.uuid;
		const recievingUserHasRead = data.receiving_user_last_read_at !== null
			&& isAfter(
				new Date(data.receiving_user_last_read_at),
				new Date(data.message.created_at),
			);

		this.isNew = !lastMessageSentByUser && data.new_messages_since_last_read;
		this.isDelivered = lastMessageSentByUser && !recievingUserHasRead;
		this.isRead = lastMessageSentByUser && recievingUserHasRead;
	}
}

class ExchangeConversationMessage {
	uuid: string;
	text: string;
	createdAt: string;
	textRelatedModel: ApiV3.TextRelatedModel | null;
	isIncoming: boolean;
	user: ApiV3.BaseUser;
	exchangeStatus: typeof exchangeStatuses[number] | undefined;
	agreementStatus: typeof agreementStatuses[number] | undefined;

	constructor(data: ApiV3.Message) {
		this.uuid = data.uuid;
		this.createdAt = data.created_at;
		this.textRelatedModel = data.text_related_model;

		const userStore = useUserStore();
		this.isIncoming = data.user.uuid !== userStore.user?.uuid;

		const exchangeStatus = data.text_related_model?.type === 'Transaction'
			? data.text_related_model.data.exchange_status
			: undefined;
		this.exchangeStatus = exchangeStatuses.find(({ id }) => id === exchangeStatus);

		const agreementStatus = data.text_related_model?.type === 'Agreement'
			? data.text_related_model.data.status
			: undefined;
		this.agreementStatus = agreementStatuses.find(({ id }) => id === agreementStatus);

		const hasStatus = exchangeStatus !== undefined || agreementStatus !== undefined;
		const getStatusConversationText = this.exchangeStatus?.conversationText ?? this.agreementStatus?.conversationText;

		this.text = hasStatus
			? getStatusConversationText ?? ''
			: data.text;

		this.user = data.user;
	}

	transactionText(conversation: ExchangeConversation) {
		const isFromAgent = this.isIncoming && this.user.uuid !== conversation.athleteUuid;
		if (!isFromAgent || !this.exchangeStatus) return this.text;
		return this.exchangeStatus.agentText(`${this.user.first_name} ${this.user.last_name}`, conversation.fullName);
	}

	agreementText(conversation: ExchangeConversation) {
		const isFromAgent = this.isIncoming && this.user.uuid !== conversation.athleteUuid;
		if (!isFromAgent || !this.agreementStatus) return this.text;
		return this.agreementStatus.agentText(`${this.user.first_name} ${this.user.last_name}`, conversation.fullName);
	}
}


export {
	SingleExchangeConversation,
	ExchangeConversationListItem,
	ExchangeConversationMessage,
	type ExchangeConversation,
};
