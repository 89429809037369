import type { ApiV3 } from '@teamworksdev/influencer-core';
import { reactive, ref, watch } from 'vue';

interface UseCrudGalleryDrawerState {
	isOpen: boolean;
	selectedGalleryIndex: number | undefined;
	selectedGallery: ApiV3.Gallery | undefined;
	onDeleteSuccess?: () => void;
	onUpdateSuccess?: () => void;
}

interface UseCrudGalleryDrawerOptions {
	onDeleteSuccess?: () => void;
	onUpdateSuccess?: () => void;
}

const DEFAULT_STATE: UseCrudGalleryDrawerState = {
	isOpen: false,
	selectedGalleryIndex: undefined,
	selectedGallery: undefined,
	onDeleteSuccess: undefined,
	onUpdateSuccess: undefined,
} as const;

const getDefaultState = () => ({ ...DEFAULT_STATE });

const state = reactive<UseCrudGalleryDrawerState>(getDefaultState());

function resetState() {
	Object.assign(state, getDefaultState());
}

const isRendered = ref<boolean>(false);

watch(() => state.isOpen, (isOpen) => {
	if (isOpen && !isRendered.value) isRendered.value = true;
	if (!isOpen) resetState();
});

export function useCrudGalleryDrawer(options?: UseCrudGalleryDrawerOptions) {
	function open() {
		state.isOpen = true;
		state.onDeleteSuccess = options?.onDeleteSuccess;
		state.onUpdateSuccess = options?.onUpdateSuccess;
	}

	function edit(gallery: ApiV3.Gallery | ApiV3.SingleGallery, index?: number) {
		state.selectedGallery = gallery;
		if (index !== undefined) state.selectedGalleryIndex = index;
		open();
	}

	return {
		state,
		open,
		edit,
		resetState,
		isRendered,
	};
}
