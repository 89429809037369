import type { AxiosRequestConfig } from 'axios';
import {
	getList,
	isApiError,
} from './utilities/provider';
import { type ApiV3 } from '@teamworksdev/influencer-core';

const RESOURCE = 'feature-flags';

export function useFeatureFlagService(config?: Omit<AxiosRequestConfig, 'key'>) {
	const getFeatureFlags = async () => {
		const response = await getList<ApiV3.FeatureFlags>(RESOURCE, {
			key: 'getFeatureFlags',
			requiresInflcrId: false,
			...config,
		});
		if (isApiError(response)) throw response;
		return response.data;
	};

	return {
		getFeatureFlags,
	};
}
