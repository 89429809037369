
import { isAxiosError, type AxiosResponse } from 'axios';
import { useMainStore } from '@/stores/mainStore';
import { i18n } from '@/plugins/i18n';
import { camelCase } from 'lodash-es';
import { getDefaultErrorToast } from '@/utils/toasts';
import { getLocalStorageItem } from '@/utils/localStorage';
import { isBefore } from 'date-fns';
import {
	type ApiResponse,
	type ApiErrorFullResponse,
	type InflcrError,
	inflcrErrorCodes,
	type ApiV3,
}	from '@teamworksdev/influencer-core';

const { t } = i18n.global;

function handleResponse<T>(response: AxiosResponse<T, any>): ApiResponse<T> {
	return {
		data: response.data,
		status: response.status,
	};
}


function handleAxiosErrorCode(code?: string): void {
	if (!code) return;
	const mainStore = useMainStore();
	const axiosErrorCodes: Record<typeof code, () => void> = {
		ERR_CANCELED: () => {},
		ERR_NETWORK: () => {
			mainStore.setToast({
				type: 'error',
				title: t('axiosErrors.errNetwork.title'),
				description: t('axiosErrors.errNetwork.message'),
			});
		},
	};
	axiosErrorCodes[code] && axiosErrorCodes[code]();
}

function handleInflcrError(error: InflcrError): void {
	const camelCode = inflcrErrorCodes.includes(error.code) ? camelCase(error.code) : 'default';
	const mainStore = useMainStore();
	mainStore.setToast({
		type: 'error',
		title: t(`inflcrErrorCodes.${camelCode}.title`),
		description: t(`inflcrErrorCodes.${camelCode}.message`),
	});
}

function handleGenericError(status: number): void {
	const mainStore = useMainStore();
	const genericErrorCodes: Record<number, () => void> = {
		401: () => {
			const authToken = getLocalStorageItem<ApiV3.AuthToken>('inflcr-token');
			if (authToken?.expires_at && isBefore(new Date(authToken.expires_at), new Date())) {
				window.location.replace('/logout');
			}
		},
		404: () => {
			mainStore.setToast({
				type: 'error',
				title: t('genericErrorCodes.404.title'),
				description: t('genericErrorCodes.404.message'),
			});
		},
		504: () => {
			mainStore.setToast({
				type: 'error',
				title: t('genericErrorCodes.504.title'),
				description: t('genericErrorCodes.504.message'),
			});
		},
	};
	if (genericErrorCodes[status]) {
		genericErrorCodes[status]();
	} else {
		mainStore.setToast(getDefaultErrorToast());
	}
}

function handleError<T>(error: unknown): ApiErrorFullResponse<T> {
	let message: string | null = null;
	let status: number | null = null;
	let data: T | undefined;

	if (isAxiosError(error)) {
		message = error.message;
		if (error.response) {
			if (error.response.data.message) {
				message = error.response.data.message;
			}
			status = error.response.status;
			data = error.response.data;
			if (!error.config?.bypassErrorToast) {
				error.response.data.code
					? handleInflcrError(error.response.data)
					: handleGenericError(error.response.status);
			}
		} else {
			if (!error.config?.bypassErrorToast) handleAxiosErrorCode(error.code);
		}
	}

	return { message, status, data };
}

export {
	handleResponse,
	handleError,
};
